import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-apply-modify',
  templateUrl: './apply-modify.component.html',
  styleUrls: ['./apply-modify.component.scss'],
})
export class ApplyModifyComponent implements OnInit {
  public uuid;
  public Corporation_Form;
  public Company_Name;
  public Company_Name_Kana;
  public Post_Code;
  public Address_1;
  public Address_2;
  public Address_3;
  public TelePhone;
  public Last_Name;
  public Name;
  public Last_Name_Kana;
  public Name_Kana;
  public Staff_Post;
  public Staff_Position;
  public Users;
  public History_Flg;
  public notKana = false;

  public Corporate_Status;
  public Corporation_No;
  public Acquired_Company_Code;
  public Acquired_Company_Name;
  public Application_Date;
  public Application_Time;
  public Judge_Status;
  public Fees;
  public Note;

  public selectedRadio;
  public AcquiredInfo = [];
  public mail_send_flg;
  public acquired_data = '';

  public Message_Code_Reg;
  public Message_Code_Pdf;

  butDisabled = false;
  butDisabled1 = true;
  public pdffile_name;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    public data: DataProvider,
    private route: ActivatedRoute,
    public rest: CallapiService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-contract-apply-list';
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.widthChanger('main-header', 942);
    this.spinner.show();
    this.rest.Get_ApplicationsId(this.data.uuid, this.uuid).subscribe(
      (res) => {
        console_log(res)
        this.Corporate_Status = res.data.corporate_status;
        this.Corporation_Form = res.data.corporation_form;
        this.Company_Name = res.data.company_name;
        this.Company_Name_Kana = res.data.company_name_kana;
        this.Post_Code = res.data.postcode;
        this.Address_1 = res.data.address_1;
        this.Address_2 = res.data.address_2;
        this.Address_3 = res.data.address_3;
        this.TelePhone = res.data.tel;
        this.Last_Name = res.data.staff_last_name;
        this.Name = res.data.staff_first_name;
        this.Last_Name_Kana = res.data.staff_last_name_kana;
        this.Name_Kana = res.data.staff_first_name_kana;
        this.Staff_Post = res.data.staff_post;
        this.Staff_Position = res.data.staff_position;
        this.History_Flg = res.data.history_flg;
        this.Corporation_No = res.data.corporation_no;
        this.Acquired_Company_Code = res.data.acquired_code;
        this.Acquired_Company_Name = res.data.acquired_name;
        this.Application_Date = res.data.app_date;
        this.Application_Time = res.data.app_time;
        this.Judge_Status = res.data.judge_state;
        this.Fees = res.data.fees;
        this.Note = res.data.note;
        this.Users = res.data.users;
        this.changeType(this.Corporation_Form);
        this.acquired_data = this.Acquired_Company_Code + ':' + this.Acquired_Company_Name;
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );
    this.rest.Get_AcquiredMaster(this.data.uuid).subscribe(
      (res) => {
        this.AcquiredInfo = res.data.AcquiredInfo;
      },
      (err) => { }
    );
  }
  onKey(data) {
    this.rest.ZipCode(data).subscribe((res) => {
      if (res.results) {
        this.Address_1 =
          res.results[0].address1 +
          res.results[0].address2 +
          res.results[0].address3;
      }
    });
  }
  selectCorporation(id) {
    this.Corporate_Status = id;
  }
  selectHistory(id) {
    this.History_Flg = id;
  }
  changeType(type) {
    this.Corporation_Form = type;
    if (type == '1') {
      document.getElementById('type1').classList.remove('btn-box-grey');
      document.getElementById('type1').classList.add('btn-box');
      document.getElementById('type2').classList.remove('btn-box');
      document.getElementById('type2').classList.add('btn-box-grey');
    } else if (type == '2') {
      document.getElementById('type2').classList.remove('btn-box-grey');
      document.getElementById('type2').classList.add('btn-box');
      document.getElementById('type1').classList.remove('btn-box');
      document.getElementById('type1').classList.add('btn-box-grey');
    } else {
      document.getElementById('type2').classList.remove('btn-box');
      document.getElementById('type2').classList.add('btn-box-grey');
      document.getElementById('type1').classList.remove('btn-box');
      document.getElementById('type1').classList.add('btn-box-grey');
    }
  }
  back(id) {
    if (id == 1) {
      this.router.navigate(['/wf-contract-apply-detail', { uuid: this.uuid }]);
    } else {
      document.getElementById('confirm').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      this.widthChanger('main-header', 942);
    }
  }
  dataValidation() {
    let err = false;
    let errTxt = '';
    let Number = /^-?\d*$/;
    if (this.Users == null || this.Users == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_MEMBER_COUNT')
        .subscribe((res) => (errTxt = res));
    } else if (this.Users != "" && !Number.test(this.Users)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_MEMBER_COUNT_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Name_Kana == null || this.Name_Kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_KITAGANA_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Last_Name_Kana == null || this.Last_Name_Kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_KITAGANA_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Name == null || this.Name == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Last_Name == null || this.Last_Name == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.TelePhone == null || this.TelePhone == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_TELEPHONE')
        .subscribe((res) => (errTxt = res));
    } else if (this.TelePhone != "" && !Number.test(this.TelePhone)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_TELEPHONE_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Address_2 == null || this.Address_2 == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_ADDRESS_2')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Address_1 == null || this.Address_1 == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_ADDRESS_1')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Post_Code == null || this.Post_Code == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_POSTCODE')
        .subscribe((res) => (errTxt = res));
    } else if (this.Post_Code != "" && !Number.test(this.Post_Code)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_POSTCODE_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.notKana === true && this.Company_Name_Kana !== null && this.Company_Name_Kana !== '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME_NOT_KANA')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Company_Name_Kana == null || this.Company_Name_Kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME_KANA')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Company_Name == null || this.Company_Name == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      return false;
    }
    return true;
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i] as HTMLElement;

      header.style.minWidth = width.toString() + 'px';
    }
  }

  input() {
    var acquired_data = this.acquired_data.split(":");
    this.Acquired_Company_Code = acquired_data[0];
    this.Acquired_Company_Name = acquired_data[1];
    if (this.dataValidation() == false) {
      return true;
    } else {
      document.getElementById('confirm').style.display = 'block';
      document.getElementById('edit').style.display = 'none';
      document.getElementById('confirm').classList.remove('row1');
      document.getElementById('edit').classList.add('row');
      this.widthChanger('main-header', 752);
    }
  }

  confirm() {
    this.mail_send_flg = '2';
    var acquired_code = this.acquired_data.split(":");
    var Users: string = this.Users.toString();
    this.spinner.show();
    this.rest.Update_Applications(
      this.data.uuid,
      this.uuid,
      this.Corporate_Status,
      this.Company_Name,
      this.Company_Name_Kana,
      this.Post_Code,
      this.Address_1,
      this.Address_2,
      this.Address_3,
      this.TelePhone,
      this.Last_Name,
      this.Name,
      this.Last_Name_Kana,
      this.Name_Kana,
      this.Staff_Post,
      this.Staff_Position,
      this.Judge_Status,
      this.Corporation_Form,
      Users,
      acquired_code[0],
      this.History_Flg,
      this.mail_send_flg
    ).subscribe(
      (res) => {
        if (window.IMAGE_RESULT) {
          this.rest.Upload_Regcertificates(this.uuid, this.data.uuid, window.IMAGE_RESULT).subscribe(
            (res) => {
              console_log(res);
              this.spinner.hide();
              this.router.navigate(['/wf-contract-apply-modify-comp']);
            },
            (err) => {
              if (err.error.error) {
                this.spinner.hide();
                this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
              } else {
                this.spinner.hide();
                this.router.navigate(['/wf-contract-err', { code: 'ERR_PDF' }]);
              }
            }
          );
        } else {
          this.spinner.hide();
          this.router.navigate(['/wf-contract-apply-modify-comp']);
        }
      },
      (err) => {
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );
  }

  selectPDF() {
    const input: HTMLElement = document.getElementById('pdffile');
    input.click();
  }

  upload(event: Event) {
    const self: ApplyModifyComponent = this;
    const reader: FileReader = new FileReader();
    reader.onload = function () {
      var pdf_string = reader.result.toString();
      var pdf_binary = pdf_string.split('data:application/pdf;base64,');
      window.IMAGE_RESULT = pdf_binary[1];
    };
    const element: HTMLInputElement = <HTMLInputElement>event.srcElement;
    if (element.files.length > 0) {
      reader.readAsDataURL(element.files[0]);
      this.pdffile_name = element.files[0].name;
    }
    element.value = '';
  }

  onKeyKana(katakana) {
    if (!this.isKana(katakana) || katakana.includes('・')) {
      this.notKana = true;
    } else {
      this.notKana = false;
    }
  }

  onKeyLastNameKana(katakana) {
    if (!this.isKana(katakana)) {
      this.Last_Name_Kana = '';
    }
  }

  onKeyNameKana(katakana) {
    if (!this.isKana(katakana)) {
      this.Name_Kana = '';
    }
  }

  isKana(ch) {
    let k = 0;
    for (let i = 0; i < ch.length; i++) {
      if (ch[i] >= "\u30a0" && ch[i] <= "\u30ff") {
        k++;
      }
    }
    if (k == ch.length) {
      return true;
    }
    return false;
  }
}
declare global {
  interface Window {
    IMAGE_RESULT?: string;
  }
}
