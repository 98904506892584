import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'adminlte';
  constructor(public translate: TranslateService,) {
    translate.addLangs(['English', 'Japanese']);
    translate.setDefaultLang('Japanese');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/English|Japanese/) ? browserLang : 'Japanese');
  }
}

