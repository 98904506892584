import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Amplify, { Auth } from 'aws-amplify';
import { DataProvider } from 'src/providers/data/data';
import { IDENTITY_POOL_ID, USER_POOL_ID, REGION, CLIENT_ID } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppService {

  constructor(private router: Router, public data: DataProvider) {
    Amplify.configure({
      Auth: {
          // REQUIRED - Amazon Cognito Identity Pool ID
          identityPoolId: IDENTITY_POOL_ID, 
          // REQUIRED - Amazon Cognito Region
          region: REGION, 
          // OPTIONAL - Amazon Cognito User Pool ID
          userPoolId: USER_POOL_ID,
          // OPTIONAL - Amazon Cognito Web Client ID
          userPoolWebClientId: CLIENT_ID, 
          // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
          mandatorySignIn: true,
          // OPTIONAL - Configuration for cookie storage
          // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
          cookieStorage: {
          // REQUIRED - Cookie domain (only required if cookieStorage is provided)
              domain: '.localhost:4200',
          // OPTIONAL - Cookie path
              path: '/',
          // OPTIONAL - Cookie expiration in days
              expires: 365,
          // OPTIONAL - Cookie secure flag
          // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
              secure: true
          },
          // OPTIONAL - customized storage object
          storage: localStorage,
          // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
          authenticationFlowType: 'USER_PASSWORD_AUTH'
      }
    });
  }

  login(username: string, password: string): Promise<any> {    
    return Auth.signIn(username, password).then(function (data) {
      return data;
    }).catch(function (err) {
    });
  }

  changePassword(oldPassword: string, newPassword: string): Promise<any> {
    return Auth.currentAuthenticatedUser()
    .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
    })
    .then(data => {
      return data;
    })
    .catch(err => console.log(err));
    // this.router.navigate(['/']);
  }

  auth(): Promise<any> {
    return Auth.currentAuthenticatedUser().then(data => {      
      this.data.uuid = data.attributes['sub'];
      this.data.loggedEmail = data.username;
      localStorage.setItem(
        'token',
        data.signInUserSession.getIdToken().getJwtToken()
      );
      return true;
    }).catch(err => {
      this.router.navigate(['/wf-login']);
      return false;
    });
  }

  forgotPassword(username: string) {
    return Auth.forgotPassword(username).then(function (data) {
      return data;
    }).catch(function (err) {
    });
  }

  forgotPasswordSubmit(username: string, code: string, newPassword: string) {    
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  logout() {
    Auth.signOut();
    localStorage.removeItem('token');
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/wf-login']);
  }
}
