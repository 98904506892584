<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'ACCOUNT_MANAGEMENT.TITLE' | translate }}
  </h3>
  <div class="card-body">
    <p class="left-border header-text2">
      {{ 'ACCOUNT_LIST.TITLE' | translate }}
    </p>
  </div>
  <div class="card-body" style="padding-left: 20px">
    <!-- Input -->
    <div class="row">
      <div class="input-group" style="margin-left: 15px; width: 194px">
        <input
          type="text"
          class="form-control"
          id="name"
          [(ngModel)]="name"
          placeholder="{{ 'ACCOUNT_MANAGEMENT.NAME_DEST' | translate }}"
        />
        <img *ngIf="!name" class="icon" src="assets/icons/user-icon.svg" />
      </div>
      <div class="input-group" style="margin-left: 15px; width: 221px">
        <input
          type="text"
          class="form-control"
          id="department_shop"
          [(ngModel)]="department_shop"
          placeholder="{{ 'ACCOUNT_MANAGEMENT.DEPARMENT_NAME_OR_STORE_NAME' | translate }}"
        />
        <i *ngIf="!department_shop" class="icon far fa-bell" style="font-size: 19px"></i>
      </div>
      <div class="input-group" style="margin-left: 15px; width: 280px">
        <input
          type="text"
          class="form-control"
          id="mail_address"
          [(ngModel)]="mail_address"
          placeholder="{{ 'ACCOUNT_MANAGEMENT.MAIL_ADDRESS_DEST' | translate }}"
        />
        <img *ngIf="!mail_address"  class="mail-icon" src="assets/icons/mail-icon.svg" />
      </div>
    </div>
    <!-- Check -->
    <div class="row checkbox-top">
      <!-- Check 1 -->
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="user_auth_type20"
              [(ngModel)]="user_auth_type20"
            />
            <label for="user_auth_type20" class="custom-control-label ftext">
              {{'ACCOUNT_LIST.SYSTEM_ADMINISTRATOR_CHECKBOX' | translate}}
            </label>
          </div>
        </div>
      </div>
      <!-- Check 2 -->
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="user_auth_type21"
              [(ngModel)]="user_auth_type21"
            />
            <label for="user_auth_type21" class="custom-control-label ftext">
              {{'ACCOUNT_LIST.WF_HEADQUARTERS_CHECKBOX' | translate}}
            </label>
          </div>
        </div>
      </div>
      <!-- Check 3 -->
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="user_auth_type24"
              [(ngModel)]="user_auth_type24"
            />
            <label for="user_auth_type24" class="custom-control-label ftext">
              {{'ACCOUNT_LIST.MERCHANT_HEADQUARTERS_CHECKBOX' | translate}}
            </label>
          </div>
        </div>
      </div>
      <!-- Check 4 -->
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="user_auth_type22"
              [(ngModel)]="user_auth_type22"
            />
            <label for="user_auth_type22" class="custom-control-label ftext">
              {{'ACCOUNT_LIST.DIRECTLY_MANAGED_STORE_STAFF_CHECKBOX' | translate}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Check 5 -->
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="user_auth_type23"
              [(ngModel)]="user_auth_type23"
            />
            <label for="user_auth_type23" class="custom-control-label ftext">
              {{'ACCOUNT_LIST.MERCHANT_STAFF_CHECKBOX' | translate}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- End check -->
    <!-- Search button -->
    <div class="row checkbox-top-2">
      <div class="btn-search-top">
        <a target="_blank" class="btn-search" (click)="Search()"
          >{{ 'CONTRACT_APP_LIST.SEARCH_BUTTON' | translate }}
          <img
            style="margin-left: 20px; right: 0%; margin-top: -3px"
            src="assets/icons/srch.svg"
          />
        </a>
      </div>
    </div>
    <!-- Limit 500 text -->
    <label class="limit-500" id="limit500">
      {{'CONTRACT_APP_LIST.SEARCH_LIMIT_500' | translate}}
    </label>
    <!-- Radio -->
    <div class="row select-radio-top">
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              checked
              id="r1"
              name="customRadio" (click)="selectedRadios(10)"
            />
            <label for="r1" class="custom-control-label ftext">{{
              'COMMON.CASE_10' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r2"
              name="customRadio" (click)="selectedRadios(20)"
            />
            <label for="r2" class="custom-control-label ftext">{{
              'COMMON.CASE_20' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r3"
              name="customRadio" (click)="selectedRadios(50)"
            />
            <label for="r3" class="custom-control-label ftext">{{
              'COMMON.CASE_50' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r4"
              name="customRadio" (click)="selectedRadios(100)"
            />
            <label for="r4" class="custom-control-label ftext">{{
              'COMMON.CASE_100' | translate
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- End radio -->
    <!-- Section -->
    <section class="content">
      <div class="container-fluid">
        <div class="row table-width">
          <!-- Table -->
          <table class="table table-bordered" style="min-width: 640px;">
            <thead>
              <tr class="text-center">
                <th class="ftext">
                  {{ 'ACCOUNT_MANAGEMENT.NAME_DEST' | translate }}
                </th>
                <th class="ftext">
                  {{
                    'ACCOUNT_MANAGEMENT.DEPARTMENT_OR_STORE_DEST' | translate
                  }}
                </th>
                <th class="ftext">
                  {{ 'ACCOUNT_MANAGEMENT.MAIL_ADDRESS_DEST' | translate }}
                </th>
                <th class="ftext">
                  {{ 'ACCOUNT_MANAGEMENT.AUTHORITY_DEST' | translate }}
                </th>
                <th class="ftext" *ngIf="wf_account_list_detail">
                  {{ 'ACCOUNT_LIST.DETAIL_BUTTON' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let i of items" class="text-center">
                <td class="tbtext tdata1">{{ i.name }}</td>
                <td class="tbtext tdata2">
                  {{ i.department_shop }}
                </td>
                <!-- <td class="tbtext tdata3" *ngIf="i.Authority == Headquarters">
                  <h1 class="tdata3-url">{{ i.mail_address }}</h1>
                </td> -->
                <td class="tbtext tdata3">
                  {{ i.mail_address }}
                </td>
                <td class="tbtext tdata4">{{ i.user_auth_name }}</td>
                <td class="tdata5" *ngIf="wf_account_list_detail">
                  <a class="btn-tb4" (click)="details(i.uuid)">
                    {{ 'ACCOUNT_LIST.DETAIL_BUTTON' | translate }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
      <div *ngIf="filteredItems.length >= 1">
        <label class="pages">{{'COMMON.PAGE' | translate}} {{ currentIndex }}/{{ pageNumber }}</label>
      </div>
      <div class="page-point" *ngIf="filteredItems.length >= 1">
        <span
          [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
          (click)="prevPage()"
          class="prev"
          >&laquo;
        </span>
        <span
          *ngFor="let page of pagesIndex"
          [ngClass]="{ tod: currentIndex == page }"
          class="dot"
          (click)="setPage(page)"
        ></span>
        <span
          [ngClass]="{ disabled: currentIndex == pageNumber || pageNumber == 0 }"
          (click)="nextPage()"
          class="next"
          >&raquo;
        </span>
      </div>
      </div>
    </section>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen]="true"><p style="color: #f77fb1 " > {{ 'COMMON.LOADING' | translate }} </p>></ngx-spinner>