import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public registerForm: FormGroup;
  public emailSend;
  public logoUrl;
  
  submitted = false;
  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.renderer.addClass(document.querySelector('app-root'), 'register-page');
      this.registerForm = this.formBuilder.group({
        emailSend: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    });
  }

  onChange() {
    document.getElementById('errormsg').innerHTML = "";
  }

  next() {
    if (this.registerForm.valid) {
      this.spinner.show();
      if(this.emailSend == '') {
        this.translate.get('RESET_PASSWORD.PLEASE_INSERT_MAIL').subscribe(
          (res) => (document.getElementById('errormsg').innerHTML = res)
        );
        this.spinner.hide();
        return;
      }
      this.appService.forgotPassword(this.emailSend).then(
        (res) => {
          this.spinner.hide();
          this.router.navigate(['/wf-change-pw', {email: this.emailSend}]);
        },
        (err)=>{
          this.spinner.hide();
          this.translate.get('RESET_PASSWORD.PASSWORD_RESET_FAILED').subscribe(
            (res) => (document.getElementById('errormsg').innerHTML = res)
          );
        }
      );      
    } else {
      this.spinner.hide();
      this.translate.get('RESET_PASSWORD.PLEASE_INSERT_MAIL').subscribe(
        (res) => (document.getElementById('errormsg').innerHTML = res)
      );
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'register-page'
    );
  }
}
