<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'ACCOUNT_LIST.TITLE' | translate }}</h3>  
  <!-- Section №1 -->
  <div id="edit">
    <div class="card-body">
      <p class="left-border header-text2">{{ 'ACCOUNT_LIST.TEXT_2' | translate }}</p>
      <p class="card-title">{{ 'ACCOUNT_LIST.TEXT_3' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.NAME_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div
              class="custom-control display col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5"
              style="width: 211px;"
            >
              <input
                type="text"
                class="form-control names"
                placeholder="{{ 'ACCOUNT_MANAGEMENT.SURNAME_PLACEHOLDER' | translate }}"
                name="Last_Name"
                [(ngModel)]="Last_Name"
              />
            </div>
            <div class="display col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5"
            style="width: 211px;">
              <input
                type="text"
                class="form-control names1"
                placeholder="{{ 'ACCOUNT_MANAGEMENT.NAME_PLACEHOLDER' | translate }}"
                name="First_Name"
                [(ngModel)]="First_Name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
            <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.AUTHORITY_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">
              {{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}
            </span>
            <div class="custom-control display col-8 col-md-6 col-xl-6">
              <select class="form-control" [(ngModel)]="User_Auth" (change)="userAuth(User_Auth)">
                <option selected value="">{{ 'ACCOUNT_MANAGEMENT.PLEASE_SELECT' | translate }}</option>
                <option *ngFor="let i of UserAuthData" value="{{i.type.toString().trimRight()}}">
                  {{i.type_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>							
    <!-- A1 and A2 and A4 DepartmentName-->
    <div class="card-body"  *ngIf="User_Auth == 'wf-sys-admin' || User_Auth == 'wf-corp-staff' || User_Auth == '' || User_Auth == 'wf-fc-head'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.DEPARTMENT_NAME' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-8 col-md-6 col-xl-6">
              <input
                  type="text" 
                  class="form-control" 
                  placeholder="{{ 'ACCOUNT_MANAGEMENT.DEPARTMENT_NAME' | translate }}" 
                  name="Department_Shop" 
                  [(ngModel)]="Department_Shop">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- A4 and A5 ManagementCompany-->
    <div class="card-body" *ngIf="User_Auth == 'wf-fc-head' || User_Auth == 'wf-fc-staff'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.MANAGEMENT_COMPANY' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-8 col-md-6 col-xl-6">
              <select class="form-control" [(ngModel)]="AcquiredData" (change)="shopNames(AcquiredData)">
                <option selected value="">{{ 'ACCOUNT_MANAGEMENT.MANAGEMENT_COMPANY_NAME' | translate }}</option>
                <option *ngFor="let i of AcquiredInfo" value="{{i.acquired_code}}:{{i.acquired_name}}">
                  {{i.acquired_code}}:{{ i.acquired_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- A3 and A5 StoreName-->
    <div class="card-body"*ngIf="User_Auth == 'wf-direct-staff' || User_Auth == 'wf-fc-staff'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.STORE_NAME_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">
              {{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}
            </span>
            <div class="custom-control display col-8 col-md-6 col-xl-6">
              <select class="form-control" [(ngModel)]="StoreName">
                <option selected value="">{{ 'ACCOUNT_MANAGEMENT.STORE_NAME_DEST' | translate }}</option>
                <option *ngFor="let i of ShopData" value="{{i.name}}">
                  {{i.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.MAIL_ADDRESS_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{'COMMON.COMMON_REQUIRE_LABEL_1' | translate}}</span>
            <div class="custom-control display col-8 col-md-6 col-xl-6">
              <input type="text" class="form-control" placeholder="account@test.co.jp" name="Mail_Address" [(ngModel)]="Mail_Address">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Button -->
    <div class="text-center">
      <!-- Error text -->
      <div class="text1 text-center" style="height: 34px">
        <p style="display: none;" id="error"></p>
      </div>
      <div class="two-btn">
        <div class="display">
          <a (click)="back(1)" class="btn-grey">{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="Next()" class="btn-pink">{{ 'ACCOUNT_LIST.MODIFY_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- Button end -->
  </div>
  <!-- End Section №1 -->

  <!-- Section №2 -->
  <section class="content" id="confirm" style="display: none;">
    <div class="card-body">
      <p class="left-border header-text2">{{ 'ACCOUNT_LIST.TEXT_4' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body ">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.NAME_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
            <h3 class="card-title display">{{ Last_Name }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ First_Name }}
              </h3>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.AUTHORITY_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title"> {{User_Auth_Name}}</h3>
        </div>
      </div>
    </div>
    <!-- DepartmentName-->
    <div class="card-body"  *ngIf="User_Auth == 'wf-sys-admin' || User_Auth == 'wf-corp-staff' || User_Auth == '' || User_Auth == 'wf-fc-head'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.DEPARTMENT_NAME' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title"> {{Department_Shop}}</h3>
        </div>
      </div>
    </div>
    <!-- ManagementCompany-->
    <div class="card-body" *ngIf="User_Auth == 'wf-fc-head' || User_Auth == 'wf-fc-staff'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.MANAGEMENT_COMPANY' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title"> {{AcquiredData}}</h3>
        </div>
      </div>
    </div>
    <!-- StoreName-->
    <div class="card-body"*ngIf="User_Auth == 'wf-direct-staff' || User_Auth == 'wf-fc-staff'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.STORE_NAME_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title"> {{StoreName}}</h3>
        </div>
      </div>
    </div>
    <!-- E-Mail -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.MAIL_ADDRESS_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title"> {{Mail_Address}}</h3>
        </div>
      </div>
    </div>
    <!-- Text -->
    <div class="text-center about">
      <p>{{ 'ACCOUNT_LIST.TEXT_CENTER_1' | translate }}</p>
    </div>
    <!-- Button -->
    <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="back(2)" class="btn-grey">{{ 'COMMON.COMMON_NO_BUTTON' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="finish()" class="btn-pink">{{ 'COMMON.COMMON_OKEY_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- Button end -->
  </section>
  <!-- End Section №2 -->
</div>
<!-- Loader -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#f77fb1 " type="square-jelly-box" [fullScreen]="true">
  <p style="color: #f77fb1 "> {{ 'COMMON.LOADING' | translate }} </p>
</ngx-spinner>