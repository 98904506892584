<div class="main-padding">
  <h3 class="header-bottom" style="font-weight: bold;">{{ 'ANNOUNCEMENT.TITLE' | translate }}</h3>
  <div class="text-center">
    <div class="finish text-center">
      {{ 'MODIFY_ANNOUNCEMENT.FINISH_TEXT_1' | translate }}<br>{{ 'MODIFY_ANNOUNCEMENT.FINISH_TEXT_2' | translate }}
    </div>
    <div class="text-center finish2">
      {{ 'MODIFY_ANNOUNCEMENT.FINISH_TEXT_3' | translate }}
    </div>
    <div class="text-center">
      <div class="col-12">
        <div class="display finish2">
          <a (click)="notification()" class="btn-grey">
            {{ 'MODIFY_ANNOUNCEMENT.FINISH_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>