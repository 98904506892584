import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/ja';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-apply-list',
  templateUrl: './apply-list.component.html',
  styleUrls: ['./apply-list.component.scss'],
})
export class ContractApplyComponent implements OnInit {
  public CompanyName = '';
  public Telephone = '';
  public AppDate_1 = '';
  public AppDate_2 = '';
  public AppDate_1_From = '';
  public AppDate_2_To = '';
  public judge_state: string = '';

  public AcquiredInfo;
  public acquired_name = '';
  public acquired_data = '';
  public documentpdf;

  public customCheckbox1;
  public customCheckbox2;
  public customCheckbox3;
  public customCheckbox4;
  public customCheckbox5;
  public customCheckbox6;
  public customCheckbox7;

  filteredItems = [];
  pages: number;
  pageSize: number = 10;
  pageNumber: number = 0;
  currentIndex: number = 1;
  items = [];
  pagesIndex: Array<number>;
  pageStart: number = 1;
  public pages1;
  public wf_contract_apply_detail;
  constructor(
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {
  }

  ngOnInit() {
    this.data.menuPageTitle = 'wf_contract-apply-list';
    const body = {
      data: {
        user_uuid: this.data.uuid,
        company_name: this.CompanyName,
        tel: this.Telephone,
        app_date_from: this.AppDate_1_From,
        app_date_to: this.AppDate_2_To,
        acquired_name: this.acquired_name,
        judge_state: this.judge_state,
      },
    };
    console_log(body);
    this.spinner.show();
    this.rest.Get_Applications(
      this.data.uuid,
      this.CompanyName,
      this.Telephone,
      this.AppDate_1_From,
      this.AppDate_2_To,
      this.acquired_name,
      this.judge_state).subscribe(
      (res) => {
        console_log(res);
        this.filteredItems = res.data;
        if (this.filteredItems.length >= 500) {
          document.getElementById('limit500').style.display = 'block';
        } else {
          document.getElementById('limit500').style.display = 'none';
        }
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
      },(err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
    this.rest.Get_AcquiredMaster(this.data.uuid).subscribe(
      (res) => {
        this.AcquiredInfo = res.data.AcquiredInfo;
        console_log(res);
      },
      (err) => {
        console_log(err);
      }
    );
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        this.pages1 = res.data;
        for (let i = 0; i < this.pages1.length; i++) {
          if (this.pages1[i].pagename == 'wf-contract-apply-detail') {
            console_log('wf-contract-apply-detail');
            if (this.pages1[i].auth == 'F' || this.pages1[i].auth == 'R') {
              this.wf_contract_apply_detail = true;
            }
            if (this.pages1[i].auth == 'D') {
              this.wf_contract_apply_detail = false;
            }
          }
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
    this.widthChanger('main-header', 360);
  }
  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }

  Search() {
    if (this.customCheckbox1 == true) {
      var c1: string = '01';
    } else {
      var c1: string = '';
    }
    if (this.customCheckbox2 == true) {
      var c2: string = '02';
    } else {
      var c2: string = '';
    }
    if (this.customCheckbox3 == true) {
      var c3: string = '05';
    } else {
      var c3: string = '';
    }
    if (this.customCheckbox4 == true) {
      var c4: string = '04';
    } else {
      var c4: string = '';
    }
    if (this.customCheckbox5 == true) {
      var c5: string = '03';
    } else {
      var c5: string = '';
    }
    if (this.customCheckbox6 == true) {
      var c6: string = '99';
    } else {
      var c6: string = '';
    }
    if (this.customCheckbox7 == true) {
      var c7: string = '90';
    } else {
      var c7: string = '';
    }
    this.judge_state = c1 + c2 + c3 + c4 + c5 + c6 + c7;
    var acquired_name = this.acquired_data.split(":");
    this.AppDate_1_From = this.datepipe.transform(this.AppDate_1, 'yyyy-MM-dd');
    this.AppDate_2_To = this.datepipe.transform(this.AppDate_2, 'yyyy-MM-dd');
    if(acquired_name[1] == undefined){
      acquired_name[1] = '';
    }
    if(this.AppDate_1_From == null){
      this.AppDate_1_From = '';
    }
    if(this.AppDate_2_To == null){
      this.AppDate_2_To = '';
    }
    const body = {
      data: {
        user_uuid: this.data.uuid,
        company_name: this.CompanyName,
        tel: this.Telephone,
        app_date_from: this.AppDate_1_From,
        app_date_to: this.AppDate_2_To,
        acquired_name: acquired_name[1],
        judge_state: this.judge_state,
      },
    };
    console_log(body);
    this.spinner.show();
    this.rest.Get_Applications(
        this.data.uuid,
        this.CompanyName,
        this.Telephone,
        this.AppDate_1_From,
        this.AppDate_2_To,
        acquired_name[1],
        this.judge_state
      ).subscribe(
        (res) => {
          this.filteredItems = res.data;
          if (this.filteredItems.length >= 500) {
            document.getElementById('limit500').style.display = 'block';
          } else {
            document.getElementById('limit500').style.display = 'none';
          }
          this.currentIndex = 1;
          this.pageStart = 1;
          this.pages = 6;

          this.pageNumber = parseInt(
            '' + this.filteredItems.length / this.pageSize
          );
          if (this.filteredItems.length % this.pageSize != 0) {
            this.pageNumber++;
          }

          if (this.pageNumber < this.pages) {
            this.pages = this.pageNumber;
          }
          this.refreshItems();
          this.spinner.hide();
        },(err)=>{
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
        }
      );
  }
  details(uuid) {
    this.router.navigate(['/wf-contract-apply-detail', { uuid }]);
  }

  pdf_download(uuid) {
    this.rest.Download_Regcertificates(this.data.uuid, uuid).subscribe(
      (res) => {
        if (res.code == 'S000001') {
          this.documentpdf = res.data.s3url;
          window.open(this.documentpdf, '_blank', '');
        }
      },
      (err) => {
        console_log(err);
      }
    );
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
  
  order(id){
    var apply_list:Array<Apply> = this.filteredItems;
    if(id == 1){
      apply_list.sort((a,b) =>  a.app_date < b.app_date ? 1 : a.app_date > b.app_date ? -1 : 0)	// descending
      this.items = apply_list.slice(
        (this.currentIndex - 1) * this.pageSize,
        this.currentIndex * this.pageSize
      );
      this.pagesIndex = this.fillArray();
    }else if(id == 2){
      apply_list.sort((a,b) => a.app_date < b.app_date ? -1 : a.app_date > b.app_date ? 1 : 0)	// Ascending
      this.items = apply_list.slice(
        (this.currentIndex - 1) * this.pageSize,
        this.currentIndex * this.pageSize
      );
      this.pagesIndex = this.fillArray();
    }else if(id == 3){
      apply_list.sort((a,b) => a.judge_state < b.judge_state ? 1 : a.judge_state > b.judge_state ? -1 : 0)	// descending
      this.items = apply_list.slice(
        (this.currentIndex - 1) * this.pageSize,
        this.currentIndex * this.pageSize
      );
      this.pagesIndex = this.fillArray();
    }else{
      apply_list.sort((a,b) => a.judge_state < b.judge_state ? -1 : a.judge_state > b.judge_state ? 1 : 0)	// Ascending
      this.items = apply_list.slice(
        (this.currentIndex - 1) * this.pageSize,
        this.currentIndex * this.pageSize
      );
      this.pagesIndex = this.fillArray();
    }
  }
  @ViewChild('date1') date1: ElementRef;
  @ViewChild('date2') date2: ElementRef;

  resetDate(id) {
    console_log(id)
    if(id == 1){
      if(this.AppDate_1 != ''){
        this.date1['displayValue'] = '';
        this.AppDate_1 = '';
        console_log(this.AppDate_1)
        if(this.AppDate_1 == ''){
          document.getElementById('clear1').style.display = 'none';
          document.getElementById('calendar1').style.display = 'block';
        }
      }
    }else{
      if(this.AppDate_2 != ''){
        this.date2['displayValue'] = '';
        this.AppDate_2 = '';
        console_log(this.AppDate_2)
        if(this.AppDate_2 == ''){
          document.getElementById('clear2').style.display = 'none';
          document.getElementById('calendar2').style.display = 'block';
        }
      }
    }
  }
  Calendar(id){
    if(id == 1){
      if(this.AppDate_1 != ''){
        document.getElementById('clear1').style.display = 'block';
        document.getElementById('calendar1').style.display = 'none';
        console_log(this.AppDate_1);
      }else{
        document.getElementById('clear1').style.display = 'none';
        document.getElementById('calendar1').style.display = 'block';
        console_log(this.AppDate_1);
      }
    }else{
      if(this.AppDate_2 != ''){
        document.getElementById('clear2').style.display = 'block';
        document.getElementById('calendar2').style.display = 'none';
      }else{
        document.getElementById('clear2').style.display = 'none';
        document.getElementById('calendar2').style.display = 'block';
      }
    }
  }
  options: DatepickerOptions = {
    minYear: getYear(new Date()) - 30, // minimum available and selectable year
    maxYear: getYear(new Date()) + 30, // maximum available and selectable year
    placeholder: '', // placeholder in case date model is null | undefined, example: 'Please pick a date'
    format: 'yyyy-MM-dd', // date format to display in input
    formatTitle: 'yyyy'+'年'+'MM'+'月',
    formatDays: 'EEEEE',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: locale, // date-fns locale
    position: 'bottom',
    inputClass: '', // custom input CSS class to be applied
    calendarClass: 'datepicker-default', // custom datepicker calendar CSS class to be applied
    scrollBarColor: '#f77fb1', // in case you customize you theme, here you define scroll bar color
  };
}
class Apply{
  acquired_name:String
  app_date:String
  company_name:String
  judge_state:String
  tel:String
  uuid:String
}