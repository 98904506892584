import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/utils/services/app.service';
import { environment } from 'src/environments/environment';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  public changePasswordForm: FormGroup;
  public email;
  public Mail_Code;
  public Password_New;
  public Password_New_2;

  public logoUrl;
  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    public data: DataProvider,
    public router: Router,
    private actRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.renderer.addClass(document.querySelector('app-root'), 'change-password-page');
    this.changePasswordForm = new FormGroup({
      Mail_Code: new FormControl(null, Validators.required),
      Password_New: new FormControl(null, Validators.required),
      Password_New_2: new FormControl(null, Validators.required),
    });
    this.email = this.actRoute.snapshot.params.email;
  }

  onChange() {
    document.getElementById('errormsgchgpass').innerHTML = "";
  }

  changePassword() {
    if (this.changePasswordForm.valid) {
      this.spinner.show();
      if(this.Mail_Code == '') {
        this.spinner.hide();
        this.translate.get('CHANGE_PASSWORD.INSERT_THE_CODE').subscribe(
          (res) => (document.getElementById('errormsgchgpass').innerHTML = res)
        );
        return;
      }
      if(this.Password_New != this.Password_New_2) {
        this.spinner.hide();
        this.translate.get('CHANGE_PASSWORD.NEW_PASS_IS_NOT_EQUAL_RETYPE_PASS').subscribe(
          (res) => (document.getElementById('errormsgchgpass').innerHTML = res)
        );
        return;
      }      
      this.appService.forgotPasswordSubmit(this.email, this.Mail_Code, this.Password_New).then(res2 => {
        this.appService.login(this.email, this.Password_New).then(res => {
          if(res !== undefined) {
            this.data.uuid = res.attributes['sub'];
            this.data.loggedEmail = this.email;
            this.data.loggedPassword = this.Password_New;
            this.spinner.hide();
            this.router.navigate(['/']);
          }
        });
      }, (err) => {
        this.spinner.hide();
        this.translate.get('CHANGE_PASSWORD.FAILED_TO_CHANGE').subscribe(
          (res) => (document.getElementById('errormsgchgpass').innerHTML = res)
        );      }); 
    } else {
      this.spinner.hide();
      this.translate.get('CHANGE_PASSWORD.FAILED_TO_CHANGE').subscribe(
        (res) => (document.getElementById('errormsgchgpass').innerHTML = res)
      );
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'change-password-page'
    );
  }
}
