<div class="main-padding">

  <section class="content" id="userdeletefinish">
    <div class="text-center logo-top">
      <div class="login-logo">
        <i class="warning"></i>
        <p id="err_title" class="error-text">
          {{ 'ETITLE.TEXT' | translate }}
        </p>
      </div>
      <div class="text-center">
        <p id="err_text" class="error-text-black"></p>
      </div>
    </div>
  </section>

</div>
