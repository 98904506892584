<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'UNPAID_LIST.TITLE' | translate }}
  </h3>
  <div class="card-body">
    <p class="left-border header-text2">
      {{ 'UNPAID_CORP_LIST.TITLE_UNPAID_CORPORATION' | translate }}
    </p>
  </div>
  <div class="card-body" style="padding-top: 0px; margin-top: -10px;">
    <!-- Input -->
    <div class="row">
      <h1 class="company-font">{{ 'UNPAID_CORP_LIST.ACQUIRED_COMPANY_NAME' | translate }}</h1>
      <h1 class="company-font">{{data.AcquiredName}}</h1>
    </div>
    <div class="row" style="margin-top: 20px;">
      <div class="input-group company-name">
        <input
          type="text"
          class="form-control"
          id="AcquiredCompanyName"
          [(ngModel)]="AcquiredCompanyName"
          placeholder="{{ 'UNPAID_CORP_LIST.COMPANY_NAME_PLACEHOLDER' | translate }}"
        />
        <img *ngIf="!AcquiredCompanyName" class="icon" src="assets/icons/book.svg" />
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="customCheckbox"
              [(ngModel)]="customCheckbox"
            />
            <label for="customCheckbox" class="custom-control-label ftext">
              {{'UNPAID_CORP_LIST.CHECKBOX_UNPAID_ONLY' | translate}}
            </label>
          </div>
        </div>
      </div>
      <div class="search-button">
        <a target="_blank" class="btn-search" (click)="Search()"
          >{{ 'CONTRACT_APP_LIST.SEARCH_BUTTON' | translate }}
          <img
            class="search-icon"
            src="assets/icons/srch.svg"
          />
        </a>
      </div>
    </div>
    <!-- Radio -->
    <div class="row select-radio-top">
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              checked
              id="r1"
              name="customRadio" (click)="selectedRadios(10)"
            />
            <label for="r1" class="custom-control-label ftext">{{
              'COMMON.CASE_10' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r2"
              name="customRadio" (click)="selectedRadios(20)"
            />
            <label for="r2" class="custom-control-label ftext">{{
              'COMMON.CASE_20' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r3"
              name="customRadio" (click)="selectedRadios(50)"
            />
            <label for="r3" class="custom-control-label ftext">{{
              'COMMON.CASE_50' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r4"
              name="customRadio" (click)="selectedRadios(100)"
            />
            <label for="r4" class="custom-control-label ftext">{{
              'COMMON.CASE_100' | translate
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- Section -->
    <section class="content">
      <div class="container-fluid">
        <div class="row table-width" id="table-width">
          <table class="table table-bordered" style="min-width: auto;">
            <thead>
              <tr class="text-center">
                  <th class="ftext">
                    {{'UNPAID_CORP_LIST.TH_1_CORPORATE_NUMBER' | translate}}
                  </th>
                  <th class="ftext">
                    {{'UNPAID_CORP_LIST.TH_2_COMPANY_NAME' | translate}}
                  </th>
                  <th class="ftext">
                    {{'UNPAID_CORP_LIST.TH_3_TELEPHONE_NUMBER' | translate}}
                  </th>
                  <th class="ftext">
                    {{'UNPAID_CORP_LIST.TH_4_ACCRUED_TOTAL' | translate}}
                  </th>
                  <th class="ftext" >
                    {{'UNPAID_CORP_LIST.TH_5_NUMBER_OF_PAYEES' | translate}}
                  </th>
                  <th class="ftext" >
                    {{'UNPAID_CORP_LIST.TH_6_UNPAID_MEMBERS' | translate}}
                  </th>
                </tr>
            </thead>
            <tbody>
              <tr class="text-center" *ngFor="let list of items">
                <td class="tbtext td1">
                  <a class="csv-url" (click)="Detail(list.uuid)">
                    {{list.corporation_no}}
                  </a>
                </td>
                <td class="tbtext td1">{{list.company_name}}</td>
                <td class="tbtext td2">{{list.tel}}</td>
                <td class="tbtext td2">{{parse(list.unpaid_amount)}}</td>
                <td class="tbtext td2">{{parse(list.unpaid_count)}}</td>
                <td class="tbtext td5">
                  <a class="btn-tb5" (click)="Unpaidmembers(list.corporation_no)">
                    {{ 'UNPAID_CORP_LIST.TH_6_UNPAID_MEMBERS' | translate}}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="filteredItems.length >= 1">
          <label class="pages">{{'COMMON.PAGE' | translate}} {{ currentIndex }}/{{ pageNumber }}</label>
        </div>          
        <div class="page-point" *ngIf="filteredItems.length >= 1">
          <span
            [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
            (click)="prevPage()"
            class="prev"
            >&laquo;</span
          >
          <span
            *ngFor="let page of pagesIndex"
            [ngClass]="{ tod: currentIndex == page }"
            class="dot"
            (click)="setPage(page)"
          ></span>
          <span
            [ngClass]="{
              disabled: currentIndex == pageNumber || pageNumber == 0
            }"
            (click)="nextPage()"
            class="next"
            >&raquo;
            </span>
        </div>
        <div class="text-center" *ngIf="back_button">
          <div class="two-btn">
            <div class="display">
              <a (click)="back()" class="btn-grey">
                {{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>