import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/utils/services/app.service';
import { environment } from 'src/environments/environment';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
  public logoUrl;
  public pages;
  public wf_myaccount_edit;
  public FirstName;
  public LastName;
  public CompanyName;

  constructor(
    private appService: AppService, 
    public data: DataProvider,
    private spinner: NgxSpinnerService,
    public rest: CallapiService,
    private router: Router,) {}

  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.spinner.show();
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        this.pages = res.data;
        for (let i = 0; i < this.pages.length; i++) {
          if (this.pages[i].pagename == 'wf-myaccount-edit') {
            if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
              this.wf_myaccount_edit = true;
            }
            if (this.pages[i].auth == 'D') {
              this.wf_myaccount_edit = false;
            }
          }
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
    this.rest.Get_WFAccount(this.data.uuid).subscribe(
      (res)=>{
        console_log(res);
        this.data.firstName = res.data.user_first_name;
        this.data.lastName = res.data.user_last_name;
        this.CompanyName = res.data.management_company_name;
        this.spinner.hide();
      },
      (err)=>{
        console_log(err);
        this.spinner.hide();
      }
    );
  }

  logout() {
    this.appService.logout();
  }
}
