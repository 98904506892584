import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-apply-edit',
  templateUrl: './apply-edit.component.html',
  styleUrls: ['./apply-edit.component.scss'],
})
export class ApplyEditComponent implements OnInit {
  public documentpdf;
  public uuid;
  public Corporation_Form;
  public Company_Name;
  public Company_Name_Kana;
  public Post_Code;
  public Address_1;
  public Address_2;
  public Address_3;
  public TelePhone;
  public Last_Name;
  public Name;
  public Last_Name_Kana;
  public Name_Kana;
  public Staff_Post;
  public Staff_Position;
  public History_Flg;
  public Corporate_Status;
  public Corporation_No;
  public Acquired_Company_Code;
  public Acquired_Company_Name;
  public Application_Date;
  public Application_Time;
  public Judge_Status;
  public Fees;
  public Note;
  public Judge_Ng_Cause;
  public Judge_Disp_Cause;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-contract-apply-list';
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.spinner.show()
    this.rest.Get_ApplicationsId(this.data.uuid, this.uuid).subscribe(
      (res) => {
        console_log(res);
        this.Corporation_Form = res.data.corporation_form;
        this.Company_Name = res.data.company_name;
        this.Company_Name_Kana = res.data.company_name_kana;
        this.Post_Code = res.data.postcode;
        this.Address_1 = res.data.address_1;
        this.Address_2 = res.data.address_2;
        this.Address_3 = res.data.address_3;
        this.TelePhone = res.data.tel;
        this.Last_Name = res.data.staff_last_name;
        this.Name = res.data.staff_first_name;
        this.Last_Name_Kana = res.data.staff_last_name_kana;
        this.Name_Kana = res.data.staff_first_name_kana;
        this.Staff_Post = res.data.staff_post;
        this.Staff_Position = res.data.staff_position;
        this.History_Flg = res.data.history_flg;
        this.Corporate_Status = res.data.corporate_status;
        this.Corporation_No = res.data.corporation_no;
        this.Acquired_Company_Code = res.data.acquired_code;
        this.Acquired_Company_Name = res.data.acquired_name;
        this.Application_Date = res.data.app_date;
        this.Application_Time = res.data.app_time;
        this.Judge_Status = res.data.judge_state;
        this.Fees = res.data.fees;
        this.Note = res.data.note;
        this.Judge_Ng_Cause = res.data.judge_ng_cause;
        this.Judge_Disp_Cause = res.data.judge_disp_cause;
        this.spinner.hide()
      },
      (err) =>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }

  dataValidation() {
    let err = false;
    let errTxt = '';
    if(this.Judge_Status == '03'){
      if (this.Judge_Disp_Cause == null || this.Judge_Disp_Cause == '') {
        err = true;
        this.translate
          .get('APPLY_EDIT_VALIDATION.REASON_FOR_NG_ON_DISPLAY')
          .subscribe((res) => (errTxt = res));
      }
      if (this.Judge_Ng_Cause == null || this.Judge_Ng_Cause == '') {
        err = true;
        this.translate
          .get('APPLY_EDIT_VALIDATION.NG_REASON')
          .subscribe((res) => (errTxt = res));
      }
      if (err) {
        document.getElementById('error').innerHTML = errTxt;
        document.getElementById('error').style.display = 'block';
        return false;
      }
      return true;
    }else if(this.Judge_Status == '99'){
      if (this.Judge_Ng_Cause == null || this.Judge_Ng_Cause == '') {
        err = true;
        this.translate
          .get('APPLY_EDIT_VALIDATION.NG_REASON')
          .subscribe((res) => (errTxt = res));
      }
      if (err) {
        document.getElementById('error').innerHTML = errTxt;
        document.getElementById('error').style.display = 'block';
        return false;
      }
      return true;
    }
  }

  Examination() {
    if (this.dataValidation() == false) {
      return true;
    } else{
      document.getElementById('error').style.display = 'none';
      document.getElementById('ExaminationConfirm').style.display = 'block';
      document.getElementById('ExaminationEdit').style.display = 'none';
    }
  }

  ExaminationConfirm() {
    var fees: string = this.Fees.toString();
    const body = {
      data: {
        user_uuid: this.data.uuid,
        uuid: this.uuid,
        judge_state: this.Judge_Status,
        judge_ng_cause: this.Judge_Ng_Cause,
        judge_disp_cause: this.Judge_Disp_Cause,
        note: this.Note,
        fees: fees,
      },
    };
    console_log(body)
    this.spinner.show()
    this.rest.Update_StatusId(
        this.data.uuid,
        this.uuid,
        this.Judge_Status,
        this.Judge_Ng_Cause,
        this.Judge_Disp_Cause,
        this.Note,
        fees
      ).subscribe(
        (res) => {
          console_log(res)
          this.router.navigate(['/wf-contract-apply-edit-comp']);
          this.spinner.hide();
        },(err) => {
          console_log(err)
          this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
          this.spinner.hide();
        }
      );
  }
  back(id) {
    if (id == 1) {
      this.router.navigate(['/wf-contract-apply-detail', { uuid: this.uuid }]);
    } else {
      document.getElementById('ExaminationConfirm').style.display = 'none';
      document.getElementById('ExaminationEdit').style.display = 'block';
    }
  }
  selectedRadios(id) {
    this.Judge_Status = id;
  }
  pdf_download() {
    this.rest
      .Download_Regcertificates(this.data.uuid, this.uuid)
      .subscribe((res) => {
        if (res.code == 'S000001') {
          this.documentpdf = res.data.s3url;
          window.open(this.documentpdf, '_blank', '');
        }
      });
  }
}
