import { Component, OnInit } from '@angular/core';
import { RESOURCE_CACHE_PROVIDER } from '@angular/platform-browser-dynamic';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isNumeric } from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { BEA_POINT_HASH, MANUAL_PDF_FC, MANUAL_PDF_HC, PT_HASH } from 'src/environments/environment';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-point-edit',
  templateUrl: './point-edit.component.html',
  styleUrls: ['./point-edit.component.scss'],
})
export class PointEditComponent implements OnInit {
  public memberID = '';
  public memberName = '';
  public Price = '';
  public Point = '';
  public dateTime = '';
  public hash = '';
  public transactionID = '';

  public lastNameKanji;
  public firstNameKanji;
  public lastNameKana;
  public firstNameKana;
  public Birthday;
  constructor(
    public data: DataProvider,
    public rest: CallapiService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public translate: TranslateService,
  ) { }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-point';
    this.spinner.show();
    this.memberID = this.data.memberID;
    this.memberName = this.data.memberName;
    this.spinner.hide();
    this.widthChanger('main-header', 942);
  }
  dataValidation() {
    let err = false;
    let errTxt = '';
    if (this.Price == null || this.Price == '' || this.Price == undefined) {
      err = true;
      this.translate
        .get('POINT_VALIDATION.ERROR_POINT_1')
        .subscribe((res) => (errTxt = res));
    }else if (this.isNumber(this.Price) == false) {
      err = true;
      this.translate
        .get('POINT_VALIDATION.ERROR_POINT_2')
        .subscribe((res) => (errTxt = res));
    }else if(Number(this.Price) < 1 || Number(this.Price) > 1000){
      err = true;
      this.translate
        .get('POINT_VALIDATION.ERROR_POINT_0')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      return false;
    }
    return true;
  }
  isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); } 
  confirm() {
    this.spinner.show();
    if (this.dataValidation() == false) {
      this.spinner.hide();
      return true;
    } else {
      this.dateTime = new Date().valueOf().toString();
      var string = this.memberID + this.dateTime + BEA_POINT_HASH;
      this.hashFunction(string).then(
        (res) => {
          console_log(res);
          this.hash = res;
          const body = {
            memberID: this.memberID,
            datetime: this.dateTime,
            hash: this.hash,
            price: Number(this.Price)
          };
          console_log(body);

          this.spinner.hide();
          this.router.navigate(['/wf-point-edit-conf']);
          this.data.Point = this.Price;

          // this.rest.getPoint(this.memberID, this.dateTime, this.hash, Number(this.Price)).subscribe(
          //   (response) => {
          //     console_log(response);
          //     this.data.Price = this.Price;
          //     this.data.Point = response.data.point;
          //     this.spinner.hide();
          //     this.router.navigate(['/wf-point-edit-conf']);
          //   },
          //   (err) => {
          //     this.translate.get('BEA_GET_POINT_ERROR.' + err.error.errorCode).subscribe(
          //       (res) => {
          //         document.getElementById('error').innerHTML = res;
          //         document.getElementById('error').style.display = 'block';
          //       }
          //     );
          //     console_log(err);
          //     this.spinner.hide();
          //   }
          // );
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
        }
      );
    }
  }
  async hashFunction(string) {
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  }
  back() {
    this.router.navigate(['/wf-point']);
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i] as HTMLElement;
      header.style.minWidth = width.toString() + 'px';
    }
  }
}