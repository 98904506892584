<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'UNPAID_LIST.TITLE' | translate }}
  </h3>
  <div class="card-body">
    <!-- Input -->
    <div class="row">  
      <h1 class="closed-date ftext">{{'UNPAID_LIST.CLOSED_DATE' | translate}} {{ClosedDate}}</h1>
    </div>
    <div class="row">
      <div class="input-group acquired-company">
        <input list="brow" class="form-control" [(ngModel)]="acquired_data" placeholder="{{'CONTRACT_APP_LIST.ACQUIRED_COMPANY_NAME_PLACEHOLDER' | translate}}">
        <datalist id="brow" required name="acquired_data">
          <option class="acquired-font" *ngFor="let i of AcquiredInfo" value="{{i.acquired_code}}:{{ i.acquired_name }}">
          </option>
        </datalist>
        <img id="book" class="icon" src="assets/icons/openbookblack.svg"/>
      </div>

      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="customCheckbox"
              [(ngModel)]="customCheckbox"
            />
            <label for="customCheckbox" class="custom-control-label ftext">
              {{'UNPAID_LIST.CHECKBOX_ONLY_FOR_UNPAID_ITEMS' | translate}}
            </label>
          </div>
        </div>
      </div>

      <div class="search-button">
        <a target="_blank" class="btn-search" (click)="Search()"
          >{{ 'CONTRACT_APP_LIST.SEARCH_BUTTON' | translate }}
          <img
            class="search-icon"
            src="assets/icons/srch.svg"
          />
        </a>
      </div>
    </div>
     <!-- Radio -->
     <div class="row select-radio-top">
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              checked
              id="r1"
              name="customRadio" (click)="selectedRadios(10)"
            />
            <label for="r1" class="custom-control-label ftext">{{
              'COMMON.CASE_10' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r2"
              name="customRadio" (click)="selectedRadios(20)"
            />
            <label for="r2" class="custom-control-label ftext">{{
              'COMMON.CASE_20' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r3"
              name="customRadio" (click)="selectedRadios(50)"
            />
            <label for="r3" class="custom-control-label ftext">{{
              'COMMON.CASE_50' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r4"
              name="customRadio" (click)="selectedRadios(100)"
            />
            <label for="r4" class="custom-control-label ftext">{{
              'COMMON.CASE_100' | translate
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- Section -->
    <section class="content">
      <div class="container-fluid">
        <div class="row table-width" id="table-width">
          <table class="table table-bordered" style="min-width: auto;">
            <thead>
              <tr class="text-center">
                  <th class="ftext">
                    {{'UNPAID_LIST.TH_1_ACQUIRED_COMPANY_CODE' | translate}}
                  </th>
                  <th class="ftext">
                    {{'UNPAID_LIST.TH_2_ACQUIRED_COMPANY_NAME' | translate}}
                  </th>
                  <th class="ftext">
                    {{'UNPAID_LIST.TH_3_ACCRUED_TOTAL' | translate}}
                  </th>
                  <th class="ftext">
                    {{'UNPAID_LIST.TH_4_NUMBER_OF_PAYEES' | translate}}
                  </th>
                  <th class="ftext" >
                    {{'UNPAID_LIST.TH_5_UNPAID_CORPORATION' | translate}}
                  </th>
                </tr>
            </thead>
            <tbody>
              <tr class="text-center" *ngFor="let list of items">
                <td class="tbtext td1">{{list.acquired_code}}</td>
                <td class="tbtext td1">{{list.acquired_name}}</td>
                <td class="tbtext td2">{{parse(list.unpaid_amount)}}</td>
                <td class="tbtext td2">{{parse(list.unpaid_count)}}</td>
                <td class="tbtext td5">
                  <a class="btn-tb5" (click)="UnpaidCorporation(list.acquired_code, list.acquired_name)">
                    {{ 'UNPAID_LIST.TH_5_UNPAID_CORPORATION' | translate}}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="filteredItems.length >= 1">
          <label class="pages">{{'COMMON.PAGE' | translate}} {{ currentIndex }}/{{ pageNumber }}</label>
        </div>          
        <div class="page-point" *ngIf="filteredItems.length >= 1">
          <span
            [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
            (click)="prevPage()"
            class="prev"
            >&laquo;</span
          >
          <span
            *ngFor="let page of pagesIndex"
            [ngClass]="{ tod: currentIndex == page }"
            class="dot"
            (click)="setPage(page)"
          ></span>
          <span
            [ngClass]="{
              disabled: currentIndex == pageNumber || pageNumber == 0
            }"
            (click)="nextPage()"
            class="next"
            >&raquo;
            </span>
        </div>
      </div>
    </section>
  </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>