import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';
interface Data {
  Date: String;
  Contents: String;
}
@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
})
export class AnnouncementComponent implements OnInit {
  data1: Data[] = [
    {
      Date: '2021/03/10',
      Contents: '定期メンテナンスがあります。',
    },
    {
      Date: '2021/03/07',
      Contents: '◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯。',
    },
    {
      Date: '2021/03/08',
      Contents: '◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯。',
    },
    {
      Date: '2021/03/09',
      Contents: '◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯。',
    },
  ];
  constructor(private router: Router, public data: DataProvider) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-announcement';
  }

  details() {
    this.router.navigate(['/']);
  }
  add() {
    this.router.navigate(['/wf-announcement-add']);
  }
  edit() {
    this.router.navigate(['/wf-announcement-edit']);
  }
  delete() {
    this.router.navigate(['/wf-announcement-delete']);
  }
}
