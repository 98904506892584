// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
};

export const IDENTITY_POOL_ID ='ap-northeast-2:8e49a4e1-62e8-4332-a09b-cd29ec146199';
export const USER_POOL_ID = 'ap-northeast-2_9rVbC5VbB';
export const CLIENT_ID = '19vvhfd75h5cuelu006rnbuohg';
export const REGION = 'ap-northeast-2';
export const MANUAL_PDF_HC = 'https://test-console.fit365-service.jp/pdf/console_manual_HC.pdf';
export const MANUAL_PDF_FC = 'https://test-console.fit365-service.jp/pdf/console_manual_FC.pdf';

export const API_URL ='https://6tl9wr3du2.execute-api.ap-northeast-2.amazonaws.com/test';
export const API_KEY = 'V2wavfRe05m7YZD2h96haW9e4rnzI76zIWXNG+Hp';

export const PT_API_URL = 'https://test-api.wf-datahub.com/mainapi/casio';
export const PT_HASH = 'qYGEsExqaRT9OwUqDsX9bsRp6KoIeuyIO1fXKSlyQRbsikuhbWk7feKEnK8dF25s';
export const BEA_POINT_HASH = 'qYGEsExqaRT9OwUqDsX9bsRp6KoIeuyIO1fXKSlyQRbsikuhbWk7feKEnK8dF25s';
export const BEA_POINT_API_URL = 'https://test-beapoint.fit365-service.jp/api';
export const POINT_NOTIFICATION_API_URL = 'https://wf-member-app-dev.herokuapp.com';



export const S3_BUCKET = 'test-corporation.fit365-service.jp';
export const S3_FOLDER_PATH = 'pdf/Contracts/';
