<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'CONTRACTED_COMPANY_MODIFY.TITLE' | translate }}
  </h3>
  <div id="edit">
    <div class="card-body">
      <p class="left-border header-text2">
        {{ 'CONTRACTED_COMPANY_MODIFY.TITLE_TEXT_2' | translate }}
      </p>
      <p>{{ 'CONTRACTED_COMPANY_MODIFY.TITLE_TEXT_3' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.START_DATE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <!-- <span class="badge badge-info">
              {{'COMMON.COMMON_REQUIRE_LABEL_1' | translate}}
            </span>
            <div class="custom-control display col-xl-10 col-10 date1">
              <ngx-datepicker [(ngModel)]="AppDate_1" [options]="options" #date1 (click)="Calendar(1)">
              </ngx-datepicker>
            </div> -->
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 style="padding-left: 45px;" class="card-title">{{ StartDate }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.END_DATE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-secondary">
              {{'COMMON.COMMON_REQUIRE_LABEL_2' | translate}}
            </span>
            <div class="custom-control display col-xl-10 col-10 date1">
              <ngx-datepicker [(ngModel)]="AppDate_2" [options]="options" #date2 (click)="Calendar(2)">
              </ngx-datepicker>
              <img id="clear2" style="display: none;" class="icon clear-icon" src="assets/icons/x.svg" (click)="resetDate(2)"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-3 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.BEFORE_DEST' | translate }}/{{
              'DETAILS.AFTER_DEST' | translate
            }}
          </h3>
        </div>
        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <!-- Shine -->
            <div class="column-check display">
              <div class="form-group">
                <div class="custom-control custom-radio"
                style="margin-left: 25px;"
                (click)="selectCorporation('1')"
                *ngIf="Corporate_Status == '1'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="c1"
                    name="customRadio"
                    checked
                  />
                  <label for="c1" class="custom-control-label ftext">{{
                    'DETAILS.BEFORE_DEST' | translate
                  }}</label>
                </div>
                <div class="custom-control custom-radio"
                style="margin-left: 25px;"
                (click)="selectCorporation('1')"
                *ngIf="Corporate_Status != '1'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="c1"
                    name="customRadio"
                    checked
                  />
                  <label for="c1" class="custom-control-label ftext">{{
                    'DETAILS.BEFORE_DEST' | translate
                  }}</label>
                </div>
              </div>
            </div>
            <div class="column-check display">
              <div class="form-group">
                <div class="custom-control custom-radio"
                style="margin-left: 25px;"
                (click)="selectCorporation('2')"
                *ngIf="Corporate_Status == '2'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="customRadio4"
                    name="customRadio"
                    checked
                  />
                  <label for="customRadio5" class="custom-control-label ftext">{{
                    'DETAILS.AFTER_DEST' | translate
                  }}</label>
                </div>
                <div class="custom-control custom-radio"
                style="margin-left: 25px;"
                (click)="selectCorporation('2')"
                *ngIf="Corporate_Status != '2'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="c2"
                    name="customRadio"
                  />
                  <label for="c2" class="custom-control-label ftext">{{
                    'DETAILS.AFTER_DEST' | translate
                  }}</label>
                </div>
              </div>
            </div>
            <div class="column-check display">
              <div class="form-group">
                <div class="custom-control custom-radio"
                style="margin-left: 25px;"
                (click)="selectCorporation('3')"
                *ngIf="Corporate_Status == '3'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="c3"
                    name="customRadio"
                    checked
                  />
                  <label for="c3" class="custom-control-label ftext">{{
                    'DETAILS.OTHERS_DEST' | translate
                  }}</label>
                </div>
                <div class="custom-control custom-radio"
                style="margin-left: 25px;"
                (click)="selectCorporation('3')"
                *ngIf="Corporate_Status != '3'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="c3"
                    name="customRadio"
                  />
                  <label for="c3" class="custom-control-label ftext">{{
                    'DETAILS.OTHERS_DEST' | translate
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.COMPANY_NAME_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-xl-10 col-10">
              <input
                type="text"
                class="form-control"
                placeholder="{{
                  'CONTRACT.COMPANY_NAME_PLACEHOLDER' | translate
                }}"
                name="Company_Name"
                [(ngModel)]="Company_Name"
              />
            </div>
          </div>
          <div class="col-12 top">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-10 col-xl-10">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'CONTRACT.COMPANY_NAME_KANA_PLACEHOLDER' | translate }}"
                name="Company_Name_Kana"
                [(ngModel)]="Company_Name_Kana"
                (ngModelChange)="onKeyKana(Company_Name_Kana)"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.ADDRESS_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-6 col-md-4 col-xl-4">
              <input
                type="text"
                class="form-control"
                maxlength="7"
                minlength="7"
                placeholder="{{ 'CONTRACT.POST_PLACEHOLDER' | translate }}"
                name="Post_Code"
                [(ngModel)]="Post_Code"
                (change)="onKey($event.target.value)"
                onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
              />
            </div>
            <div class="display">
              <h3 class="font">{{ 'CONTRACT.TEXT_1' | translate }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row top">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"></h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-10 col-xl-10">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'CONTRACT.ADDRESS_1_PLACEHOLDER' | translate }}"
                name="Address_1"
                [(ngModel)]="Address_1"
              />
            </div>
          </div>
          <div class="col-12 top">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-10 col-xl-10">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'CONTRACT.ADDRESS_2_PLACEHOLDER' | translate }}"
                name="Address_2"
                [(ngModel)]="Address_2"
              />
            </div>
          </div>
          <div class="col-12 top lef">
            <div class="custom-control display col-10 col-xl-10">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'CONTRACT.ADDRESS_3_PLACEHOLDER' | translate }}"
                name="Address_3"
                [(ngModel)]="Address_3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.TELEPHONE_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-8 col-md-6 col-xl-6">
              <input
                type="text"
                class="form-control"
                placeholder="0000000000"
                name="TelePhone"
                [(ngModel)]="TelePhone"
                maxlength="11"
                onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
              />
            </div>
            <div class="display">
              <h3 class="font">{{ 'CONTRACT.TEXT_1' | translate }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mail -->
    <!-- <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'CONTRACTED_COMPANY_LIST.MAIL_ADDRESS_COLUMN' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-8 col-md-8 col-xl-8">
              <input
                type="text"
                class="form-control"
                placeholder="sample_mail@abcd.co.jp"
                name="Mail"
                [(ngModel)]="Mail"
              />
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.MAIL_ADDRESS' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 style="padding-left: 45px;" class="card-title">{{ Mail }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 5 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.CHARGER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div
              class="custom-control display col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5"
              style="width: 211px;"
            >
              <input
                type="text"
                class="form-control names"
                placeholder="{{ 'CONTRACT.SURNAME_PLACEHOLDER' | translate }}"
                name="Last_Name"
                [(ngModel)]="Last_Name"
              />
            </div>
            <div class="display col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5"
            style="width: 211px;">
              <input
                type="text"
                class="form-control names1"
                placeholder="{{ 'CONTRACT.NAME_PLACEHOLDER' | translate }}"
                name="Name"
                [(ngModel)]="Name"
              />
            </div>
          </div>
          <div class="col-12 top">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div
              class="custom-control display col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5"
              style="width: 211px;"
            >
              <input
                type="text"
                class="form-control names"
                placeholder="{{
                  'CONTRACT.SURNAME_KANA_PLACEHOLDER' | translate
                }}"
                name="Last_Name_Kana"
                [(ngModel)]="Last_Name_Kana"
                (ngModelChange)="onKeyLastNameKana(Last_Name_Kana)"
              />
            </div>
            <div class="display col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5"
            style="width: 211px;">
              <input
                type="text"
                class="form-control names1"
                placeholder="{{ 'CONTRACT.NAME_KANA_PLACEHOLDER' | translate }}"
                name="Name_Kana"
                [(ngModel)]="Name_Kana"
                (ngModelChange)="onKeyNameKana(Name_Kana)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 6 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.DEPARTMENT_POSITION_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-secondary">{{
              'COMMON.COMMON_REQUIRE_LABEL_2' | translate
            }}</span>
            <div
              class="custom-control display col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5"
              style="width: 211px;"
            >
              <input
                type="text"
                class="form-control names"
                placeholder="{{ 'CONTRACT.DEPARTMENT_PLACEHOLDER' | translate }}"
                name="Staff_Post"
                [(ngModel)]="Staff_Post"
              />
            </div>
            <div class="display col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5"
            style="width: 211px;">
              <input
                type="text"
                class="form-control names1"
                placeholder="{{ 'CONTRACT.POSITION_PLACEHOLDER' | translate }}"
                name="Staff_Position"
                [(ngModel)]="Staff_Position"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 7 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACT.NUMBER_OF_USERS_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div
              class="custom-control display col-8 col-sm-5 col-md-5 col-lg-5 col-xl-5"
            >
              <input
                type="text"
                class="form-control"
                placeholder="15"
                name="Users"
                [(ngModel)]="Users"
                maxlength="11"
                onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 8 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.USAGE_HISTORY_POSITION_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <!-- Shine -->
            <div class="column-check display">
              <div class="form-group">
                <div class="custom-control custom-radio"
                style="margin-left: 25px;"
                (click)="selectHistory('1')"
                *ngIf="History_Flg == '1'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="R1"
                    name="customRadio1"
                    checked
                  />
                  <label for="R1" class="custom-control-label card-title1">{{
                    'CONTRACT.USE_USED_HISTORY_RADIO_BUTTON' | translate
                  }}</label>
                </div>
                <div class="custom-control custom-radio"
                style="margin-left: 25px;"
                (click)="selectHistory('1')"
                *ngIf="History_Flg != '1'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="R1"
                    name="customRadio1"
                  />
                  <label for="R1" class="custom-control-label card-title1">{{
                    'CONTRACT.USE_USED_HISTORY_RADIO_BUTTON' | translate
                  }}</label>
                </div>
              </div>
            </div>
            <div class="column-check display">
              <div class="form-group">
                <div class="custom-control custom-radio"
                style="margin-left: 25px;"
                (click)="selectHistory('0')"
                *ngIf="History_Flg == '0'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="R2"
                    name="customRadio1"
                    checked
                  />
                  <label for="R2" class="custom-control-label card-title1">{{
                    'CONTRACT.DON_T_USE_USED_HISTORY_RADIO_BUTTON' | translate
                  }}</label>
                </div>
                <div class="custom-control custom-radio"
                style="margin-left: 25px;"
                (click)="selectHistory('0')"
                *ngIf="History_Flg != '0'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="R2"
                    name="customRadio1"
                  />
                  <label for="R2" class="custom-control-label card-title1">{{
                    'CONTRACT.DON_T_USE_USED_HISTORY_RADIO_BUTTON' | translate
                  }}</label>
                </div>
              </div>
            </div>
            <div class="text1">
              <p>
                {{ 'CONTRACT.RED_TEXT_1' | translate }} <br />{{
                  'CONTRACT.RED_TEXT_2' | translate
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 9 -->
    <div class="card-body" style="margin-top: -30px;">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.USAGE_FORM' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 style="padding-left: 45px;" class="card-title" *ngIf="Corporation_Form == '1'">
                {{ 'CONTRACT.CONTRACTED_COMPANY_EMPLOYEE' | translate }}
              </h3>
              <h3 style="padding-left: 45px;" class="card-title" *ngIf="Corporation_Form == '2'">
                {{ 'CONTRACT.HEALTH_INSURANCE' | translate }}
              </h3>
              <h3 style="padding-left: 45px;" class="card-title" *ngIf="Corporation_Form == '3'">
                {{ 'CONTRACT.WElFARE' | translate }}
              </h3>
            </div>
          </div>
          <div class="col-12">
            <div class="txt_pdf">
              <p>{{ 'CONTRACTED_COMPANY_DETAIL.BLACK_TEXT_3' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 10 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACT.REGISTRATION_COPY_PDF' | translate }}
          </h3>
        </div>
        <div class="col-12 col-md-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-secondary">{{
              'COMMON.COMMON_REQUIRE_LABEL_2' | translate
            }}</span>
            <div class="col-8 custom-control display">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'CONTRACT.NOT_SELECTED_TEXT' | translate }}"
                name="pdffile_name"
                [(ngModel)]="pdffile_name"
                disabled
              />
            </div>
            <div class="custom-control display col-xl-3 col-md-5 col-10">
              <button
                class="form-control select-pdf"
                (click)="selectPDF()"
                style="width: 95px; font-size: 12px"
              >
                {{ 'CONTRACT.SELECT_FILES' | translate }}
              </button>
              <input
                type="file"
                accept="application/pdf"
                id="pdffile"
                (change)="upload($event)"
                style="display: none"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
     <!-- 11 -->
     <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACT.CONTRACT_PDF' | translate }}
          </h3>
        </div>
        <div class="col-12 col-md-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-secondary">{{
              'COMMON.COMMON_REQUIRE_LABEL_2' | translate
            }}</span>
            <div class="col-8 custom-control display">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'CONTRACT.NOT_SELECTED_TEXT' | translate }}"
                name="pdffile_name_s3"
                [(ngModel)]="pdffile_name_s3"
                disabled
              />
            </div>
            <div class="custom-control display col-xl-3 col-md-5 col-10">
              <button
                class="form-control select-pdf"
                (click)="selectPDFS3()"
                style="width: 95px; font-size: 12px"
              >
                {{ 'CONTRACT.SELECT_FILES' | translate }}
              </button>
              <input
                type="file"
                accept="application/pdf"
                id="pdffileS3"
                (change)="uploadS3($event)"
                style="display: none"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 12 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.ACQUIRED_COMPANY_CODE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{
              'COMMON.COMMON_REQUIRE_LABEL_1' | translate
            }}</span>
            <div class="custom-control display col-8 col-md-8 col-xl-8">
              <select class="form-control" [(ngModel)]="acquired_data">
                <option *ngFor="let i of AcquiredInfo" value="{{i.acquired_code}}:{{i.acquired_name}}">
                  {{i.acquired_code}}:{{ i.acquired_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- Buttons -->
      <div class="text-center">
        <div class="text1 text-center" style="height: 34px;min-width: 874px;">
          <p style="display: none;" id="error"></p>
        </div>
        <div class="two-btn">
          <div class="display">
            <a (click)="back(1)" class="btn-grey"
              >{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
            </a>
          </div>
          <div class="display">
            <a (click)="input()" class="btn-pink"
              >{{ 'COMMON.COMMON_NEXT_BUTTON' | translate }}
            </a>
          </div>
        </div>
      </div>
  </div>
  <!--  -->
  <!--  -->
  <!--  -->
  <!--  -->
  <section class="content" id="confirm" style="display: none">
    <div class="card-body">
      <p class="left-border header-text2">
        {{ 'CONTRACTED_COMPANY_MODIFY.TITLE_TEXT_2' | translate }}
      </p>
      <p>{{ 'CONTRACTED_COMPANY_MODIFY.TITLE_TEXT_4' | translate }}</p>
    </div>

    <!-- 1 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.START_DATE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title">{{ StartDate }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.END_DATE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title">{{ EndDate }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 3 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.BEFORE_OR_AFTER' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title" *ngIf="Corporate_Status == '1'">
                {{ 'DETAILS.BEFORE_DEST' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporate_Status == '2'">
                {{ 'DETAILS.AFTER_DEST' | translate }}
              </h3>
              <h3 class="card-title long-text" *ngIf="Corporate_Status == '3'">
                {{ 'DETAILS.OTHERS_DEST' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 4 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.COMPANY_NAME' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title">{{ Company_Name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest"></div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 style="margin-top: -25px" class="card-title">
                {{ Company_Name_Kana }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 5 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.ADDRESS' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title">
                {{ Post_Code }}<br />{{ Address_1 }}<br />{{ Address_2 }}<br />{{
                  Address_3
                }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 6 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.TELEPHONE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title">{{ TelePhone }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 7 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.MAIL_ADDRESS' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title">{{ Mail }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 8 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.CHARGER_NAME' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
              <h3 class="card-title display">{{ Last_Name }}</h3>
              <h3 class="card-title" style="padding-left: 18px">{{ Name }}</h3>
            </div>
          </div>
          <div class="col-12">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
              <h3 class="card-title display">{{ Last_Name_Kana }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ Name_Kana }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 9 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.DEPARTMENT_POSITION' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title display">{{ Staff_Post }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ Staff_Position }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 10 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.NUMBER_OF_USERS' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title">{{ Users }}人</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 10 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.USAGE_HISTORY' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title" *ngIf="History_Flg == '1'">
                {{ 'CONTRACT.USE_USED_HISTORY_RADIO_BUTTON' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="History_Flg == '0'">
                {{ 'CONTRACT.DON_T_USE_USED_HISTORY_RADIO_BUTTON' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 11 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACT.USAGE_FORM_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title" *ngIf="Corporation_Form == '1'">
                {{ 'CONTRACT.CONTRACTED_COMPANY_EMPLOYEE' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporation_Form == '2'">
                {{ 'CONTRACT.HEALTH_INSURANCE' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporation_Form == '3'">
                {{ 'CONTRACT.WElFARE' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 11 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.REGISTRATION_COPY' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <a class="btn-pdf pdf-text" (click)="pdf_download()">
                <i class="nav-icon pdf"></i>
                {{
                  'CONTRACTED_COMPANY_DETAIL.REGISTRATION_COPY_PDF' | translate
                }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
     <!-- 12 -->
     <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.CONTRACT_PDF' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <a class="btn-pdf pdf-text" (click)="pdf_download_s3()" *ngIf="ContractsFLG == 1 || pdffile != undefined">
                <i class="nav-icon pdf"></i>
                {{
                  'CONTRACTED_COMPANY_DETAIL.CONTRACT_PDF_BUTTON' | translate
                }}
              </a>
              <a class="btn-pdf btn-disable pdf-text"  *ngIf="ContractsFLG == 0 && pdffile == undefined || pdffile == ''">
                <i class="nav-icon pdf"></i>
                {{
                  'CONTRACTED_COMPANY_DETAIL.CONTRACT_PDF_BUTTON' | translate
                }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 13 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.ACQUIRED_COMPANY_CODE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Acquired_Company_Code }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 14 -->
    <div class="card-body">
      <div class="row1">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.ACQUIRED_COMPANY_NAME' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-confirm">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Acquired_Company_Name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="text-center">
      <div class="two-btn1">
        <div class="display">
          <a (click)="back(2)" class="btn-grey"
            >{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="confirm()" class="btn-pink"
            >{{ 'CONTRACTED_COMPANY_DETAIL.MODIFY' | translate }}
          </a>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- Loader -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#f77fb1 " type="square-jelly-box" [fullScreen]="true">
  <p style="color: #f77fb1 "> {{ 'COMMON.LOADING' | translate }} </p>
</ngx-spinner>
