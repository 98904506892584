import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { DatePipe } from '@angular/common';
import { PT_HASH } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-point',
  templateUrl: './point.component.html',
  styleUrls: ['./point.component.scss'],
})
export class PointComponent implements OnInit {
  public memberID;
  public dateTime;
  public hash;
  public lastNameKanji;
  public firstNameKanji;
  public lastNameKana;
  public firstNameKana;
  public Birthday;
  public transactionID = '';
  public Description = 1;
  constructor(
    public data: DataProvider,
    public rest: CallapiService,
    public router: Router,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
    public translate: TranslateService,
  ) { }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-point';
    this.spinner.show();
    this.spinner.hide();
  }
  selectedRadios(id) {
    this.Description = id;
    document.getElementById('error').style.display = 'none';
  }
  dataValidation() {
    let err = false;
    let errTxt = '';
    let errTxt1 = '';
    let errTxt2 = '';
    let errTxt3 = '';
    let errTxt4 = '';
    let errTxt5 = '';
    let errTxt6 = '';
    let errTxt7 = '';
    if(this.Description == 1){
      let digit = this.digits_count(this.memberID);
      if (digit < 10 || this.isNumber(this.memberID) == false) {
        err = true;
        this.translate
          .get('POINT_VALIDATION.ERROR_MEMBER_ID_10_DIGIT')
          .subscribe((res) => (errTxt = res));
      }
      if (this.memberID == null || this.memberID == '' || this.memberID == undefined) {
        err = true;
        this.translate
          .get('POINT_VALIDATION.ERROR_MEMBER_ID')
          .subscribe((res) => (errTxt = res));
      }
    }else{
      let digit8 = this.digits_count(this.Birthday);
      if (digit8 < 8 || 
        this.isNumber(this.Birthday) == false || 
        this.Birthday.substr(4, 2) > 12 || 
        this.Birthday.substr(4, 2).toString() < '01' || 
        this.Birthday.substr(6, 2) > 31 || 
        this.Birthday.substr(6, 2).toString() < '01') {
        err = true;
        this.translate
          .get('POINT_VALIDATION.ERROR_BIRTHDAY_8_DIGIT')
          .subscribe((res) => (errTxt5 = res));
      }
      if (this.Birthday == null || this.Birthday == '' || this.Birthday == undefined) {
        err = true;
        this.translate
          .get('POINT_VALIDATION.ERROR_BIRTHDAY')
          .subscribe((res) => (errTxt5 = res));
      }
      if (this.firstNameKana == null || this.firstNameKana == '' || this.firstNameKana == undefined) {
        err = true;
        this.translate
          .get('POINT_VALIDATION.ERROR_FIRSTNAMEKANA_1')
          .subscribe((res) => (errTxt4 = res));
      }else if (!this.isKana(this.firstNameKana)) {
        err = true;
        this.translate
          .get('POINT_VALIDATION.ERROR_FIRSTNAMEKANA_2')
          .subscribe((res) => (errTxt4 = res));
      }
      if (this.lastNameKana == null || this.lastNameKana == '' || this.lastNameKana == undefined) {
        err = true;
        this.translate
          .get('POINT_VALIDATION.ERROR_LASTNAMEKANA_1')
          .subscribe((res) => (errTxt3 = res));
      }else if (!this.isKana(this.lastNameKana)) {
        err = true;
        this.translate
          .get('POINT_VALIDATION.ERROR_LASTNAMEKANA_2')
          .subscribe((res) => (errTxt3 = res));
      }
      if (this.firstNameKanji == null || this.firstNameKanji == '' || this.firstNameKanji == undefined) {
        err = true;
        this.translate
          .get('POINT_VALIDATION.ERROR_FIRSTNAMEKANJI')
          .subscribe((res) => (errTxt2 = res));
      }
      if (this.lastNameKanji == null || this.lastNameKanji == '' || this.lastNameKanji == undefined) {
        err = true;
        this.translate
          .get('POINT_VALIDATION.ERROR_LASTNAMEKANJI')
          .subscribe((res) => (errTxt1 = res));
      }
    }
    if (err) {
      let br = '<br>'
      errTxt1 ? errTxt1 = errTxt1 + br : errTxt1
      errTxt2 ? errTxt2 = errTxt2 + br : errTxt2
      errTxt3 ? errTxt3 = errTxt3 + br : errTxt3
      errTxt4 ? errTxt4 = errTxt4 + br : errTxt4
      errTxt5 ? errTxt5 = errTxt5 + br : errTxt5
    if(this.Description == 1){
      document.getElementById('error').innerHTML = errTxt;
    }else{
      errTxt = errTxt1 + errTxt2 + errTxt3 + errTxt4 + errTxt5;
      document.getElementById('error').innerHTML = errTxt;
    }
      document.getElementById('error').style.display = 'block';
      return false;
    }
    return true;
  }
  isKana(ch) {
    let k = 0;
    for(let i = 0; i < ch.length; i++) {
      if(ch[i] >= "\u30a0" && ch[i] <= "\u30ff") {
        k++;
      }
    }
    if(k == ch.length) {
      return true;
    }
    return false;
  }
  digits_count(string) {
    if(string == '' || string == null){
      return 0;
    }else{
      return string.length;
    }
  }
  
  isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); } 
  async hashFunction(string) {
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  }
  search() {
    this.spinner.show();
    if (this.dataValidation() == false) {
      this.spinner.hide();
      return true;
    } else {
      document.getElementById('error').style.display = 'none';
      if(this.Description == 1){
        this.dateTime = new Date().valueOf().toString();
        var string = this.memberID + this.dateTime + PT_HASH;
        this.hashFunction(string).then(
          (res) => {
            console_log(res);
            this.hash = res;
            const body = {
              memberID: this.memberID,
              datetime: this.dateTime,
              hash: this.hash,
              transactionID: this.transactionID
            };
            console_log(body);
            this.rest.memberSelect1(this.memberID, this.dateTime, this.hash, this.transactionID).subscribe(
              (res) => {
                console_log(res);
                if (res.result == 'NG') {
                  this.translate.get('BEA_MEMBER_SELECT_ID_ERROR.' + res.errorCode).subscribe(
                    (res) => {
                      document.getElementById('error').innerHTML = res;
                      document.getElementById('error').style.display = 'block';
                    }
                  );
                  this.spinner.hide();
                }else{
                  this.memberID = res.data.memberNo;
                  this.data.memberID = res.data.memberNo;
                  this.data.memberName = res.data.memberName;
                  this.data.ClubCode = res.data.clubCode;
                  this.dateTime = new Date().valueOf().toString();
                  var string = this.memberID + this.dateTime + PT_HASH;
                  this.hashFunction(string).then(
                    (res) => {
                      this.hash = res;
                      const contractbody = {
                        memberID: this.memberID,
                        datetime: this.dateTime,
                        hash: this.hash,
                        clubCode: this.data.ClubCode,
                        transactionID: this.transactionID
                      };
                      console_log(contractbody);
                      this.rest.contractSelect(this.memberID, this.dateTime, this.hash, this.data.ClubCode, this.transactionID).subscribe(
                        (res) => {
                          console_log(res);
                          if (res.result == 'NG') {
                            this.spinner.hide();
                            this.translate.get('BEA_MEMBER_SELECT_ID_ERROR.' + res.errorCode).subscribe(
                            (res) => {
                              document.getElementById('error').innerHTML = res;
                              document.getElementById('error').style.display = 'block';
                              }
                            );
                          }else{
                            this.data.ContractTypeCode = res.data.currentSsCode;
                            this.spinner.hide();
                            this.router.navigate(['/wf-point-edit']);
                          }
                        },
                        (err) => {
                          console_log(err);
                          this.spinner.hide();
                        }
                      );
                    }
                  );
                }
              },
              (err) => {
                console_log(err);
                this.spinner.hide();
              }
            );
          },
          (err) => {
            console_log(err);
            this.spinner.hide();
          }
        );
      }else{
        this.dateTime = new Date().valueOf().toString();
        var string = this.dateTime + PT_HASH;
        this.hashFunction(string).then(
          (res) => {
            this.hash = res;
            const body = {
              datetime: this.dateTime,
              hash: this.hash,
              lastNameKanji: this.lastNameKanji,
              firstNameKanji: this.firstNameKanji,
              lastNameKana: this.lastNameKana,
              firstNameKana: this.firstNameKana,
              birthday: this.Birthday,
              transactionID: this.transactionID
            };
            console_log(body);
            this.rest.memberSelect2(
              this.dateTime,
              this.hash,
              this.lastNameKanji,
              this.firstNameKanji,
              this.lastNameKana,
              this.firstNameKana,
              this.Birthday,
              this.transactionID
            ).subscribe(
              (res) => {
                console_log(res);
                if (res.result == 'NG') {
                  this.translate.get('BEA_MEMBER_SELECT_NAME_ERROR.' + res.errorCode).subscribe(
                    (res) => {
                      document.getElementById('error').innerHTML = res;
                      document.getElementById('error').style.display = 'block';
                    }
                  );
                  this.spinner.hide();
                }else{
                  this.memberID = res.data.memberNo;
                  this.data.memberID = res.data.memberNo;
                  this.data.memberName = res.data.memberName;
                  this.data.ClubCode = res.data.clubCode;
                  this.dateTime = new Date().valueOf().toString();
                  var string = this.memberID + this.dateTime + PT_HASH;
                  this.hashFunction(string).then(
                    (res) => {
                      this.hash = res;
                      const contractbody = {
                        memberID: this.memberID,
                        datetime: this.dateTime,
                        hash: this.hash,
                        clubCode: this.data.ClubCode,
                        transactionID: this.transactionID
                      };
                      console_log(contractbody);
                      this.rest.contractSelect(this.memberID, this.dateTime, this.hash, this.data.ClubCode, this.transactionID).subscribe(
                        (res) => {
                          console_log(res);
                          if (res.result == 'NG') {
                            this.spinner.hide();
                            this.translate.get('BEA_MEMBER_SELECT_NAME_ERROR.' + res.errorCode).subscribe(
                            (res) => {
                              document.getElementById('error').innerHTML = res;
                              document.getElementById('error').style.display = 'block';
                              }
                            );
                          }else{
                            this.spinner.hide();
                            this.data.ContractTypeCode = res.data.currentSsCode;
                            this.router.navigate(['/wf-point-edit']);
                          }
                        },
                        (err) => {
                          console_log(err);
                          this.spinner.hide();
                        }
                      );
                    }
                  );
                }
              },
              (err) => {
                console_log(err);
                this.spinner.hide();
              }
            );
          },
          (err) => {
            console_log(err);
            this.spinner.hide();
          }
        );
      }
    }
  }
}