<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'UNPAID_LIST.TITLE' | translate }}
  </h3>
  <div class="card-body">
    <p class="left-border header-text2">
      {{ 'UNPAID_USER_LIST.TITLE_UNPAID_MEMBERS' | translate }}
    </p>
  </div>
  <div class="card-body">
    <div class="row row-detail">
      <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 company-font">
        <h3 class="card-title">
          {{ 'UNPAID_USER_LIST.COMPANY_NAME' | translate }}
        </h3>
      </div>
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 value-font">
            <h3 class="card-title long-text">{{ AcquiredCompanyName }}</h3>
      </div>
      <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 company-font">
        <h3 class="card-title">
          {{ 'UNPAID_USER_LIST.TELEPHONE_NUMBER' | translate }}
        </h3>
      </div>
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 value-font">
        <h3 class="card-title long-text">{{ Telephone }}</h3>
      </div>
    </div>
    <div class="row row-detail" style="padding-top: 20px;">
      <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 company-font">
        <h3 class="card-title">
          {{ 'UNPAID_USER_LIST.NAME_OF_PERSON_IN_CHARGE' | translate }}
        </h3>
      </div>
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 value-font">
            <h3 class="card-title long-text">{{ Name }}</h3>
      </div>
      <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 company-font">
        <h3 class="card-title">
          {{ 'UNPAID_USER_LIST.DEPARTMENT_NAME_TITLE' | translate }}
        </h3>
      </div>
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 value-font">
        <h3 class="card-title long-text">{{ DepartmentName }}</h3>
      </div>
    </div>
    <div class="row">
      <h1 class="company-font" style="padding-left: 15px; padding-top: 13.5px;">{{ 'UNPAID_USER_LIST.UNPAID_STATUS' | translate }}</h1>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="customCheckbox1"
              [(ngModel)]="customCheckbox1" (click)="FilterUnpaidStatus()"
            />
            <label for="customCheckbox1" class="custom-control-label ftext">
              {{'UNPAID_USER_LIST.UNPAID_CHECKBOX_1' | translate}}
            </label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="customCheckbox2"
              [(ngModel)]="customCheckbox2" (click)="FilterUnpaidStatus()"
            />
            <label for="customCheckbox2" class="custom-control-label ftext">
              {{'UNPAID_USER_LIST.UNPAID_CHECKBOX_2' | translate}}
            </label>
          </div>
        </div>
      </div>
    </div>
      <!-- Radio -->
      <div class="row select-radio-top">
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="radio"
                checked
                id="r1"
                name="customRadio" (click)="selectedRadios(10)"
              />
              <label for="r1" class="custom-control-label ftext">{{
                'COMMON.CASE_10' | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="radio"
                id="r2"
                name="customRadio" (click)="selectedRadios(20)"
              />
              <label for="r2" class="custom-control-label ftext">{{
                'COMMON.CASE_20' | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="radio"
                id="r3"
                name="customRadio" (click)="selectedRadios(50)"
              />
              <label for="r3" class="custom-control-label ftext">{{
                'COMMON.CASE_50' | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="radio"
                id="r4"
                name="customRadio" (click)="selectedRadios(100)"
              />
              <label for="r4" class="custom-control-label ftext">{{
                'COMMON.CASE_100' | translate
              }}</label>
            </div>
          </div>
        </div>
      </div>
    <!-- Section -->
    <section class="content">
      <div class="container-fluid">
        <div class="row table-width" id="table-width">
          <table class="table table-bordered" style="min-width: 500px;">
            <thead>
              <tr class="text-center">
                  <th class="ftext">
                    {{'UNPAID_USER_LIST.TH_1_STORE_NAME' | translate}}
                  </th>
                  <th class="ftext">
                    {{'UNPAID_USER_LIST.TH_2_EMPLOYEE_NUMBER' | translate}}
                  </th>
                  <th class="ftext">
                    {{'UNPAID_USER_LIST.TH_3_NAME' | translate}}
                  </th>
                  <th class="ftext">
                    {{'UNPAID_USER_LIST.TH_4_TOTAL_ACCOUNTS_PAYABLE' | translate}}
                  </th>
                  <th class="ftext">
                    {{'UNPAID_USER_LIST.TH_5_UNPAID_STATUS' | translate}}
                  </th>
                  <th class="ftext">
                    {{'UNPAID_USER_LIST.TH_6_WITHDRAWAL_DATE' | translate}}
                  </th>
                  <th class="ftext">
                    {{'UNPAID_USER_LIST.TH_7_PAYMENT_DATE' | translate}}
                  </th>
                  <th class="ftext" *ngIf="Apply_Column">
                    {{'UNPAID_USER_LIST.TH_8_APPLY' | translate}}
                  </th>
                </tr>
            </thead>
            <tbody>
              <tr class="text-center" *ngFor="let list of items">
                <td class="tbtext td1">{{list.club_name}}</td>
                <td class="tbtext td1">{{list.user_no}}</td>
                <td class="tbtext td1">{{list.user_name}}</td>
                <td class="tbtext td2">{{list.unpaid_amount}}</td>
                <td class="tbtext td1">{{list.unpaid_status}}</td>
                <td class="tbtext td1">{{list.end_date}}</td>
                <td class="tbtext td1">{{list.pay_date}}</td>
                <td class="tbtext td5" *ngIf="list.apply_flg != '1' && Apply_Column">
                  <a class="btn-tb5" (click)="Apply(list.casio_id, list.user_no, list.user_name, list.unpaid_amount, list.end_date)">
                    {{ 'UNPAID_USER_LIST.TH_8_APPLY' | translate}}
                  </a>
                </td>
                <td class="tbtext td5" *ngIf="list.apply_flg == '1' && Apply_Column">
                  <a class="btn-tb-disable">
                    {{ 'UNPAID_USER_LIST.TH_8_ALREADY' | translate}}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="filteredItems.length >= 1">
          <label class="pages">{{'COMMON.PAGE' | translate}} {{ currentIndex }}/{{ pageNumber }}</label>
        </div>          
        <div class="page-point" *ngIf="filteredItems.length >= 1">
          <span
            [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
            (click)="prevPage()"
            class="prev"
            >&laquo;</span
          >
          <span
            *ngFor="let page of pagesIndex"
            [ngClass]="{ tod: currentIndex == page }"
            class="dot"
            (click)="setPage(page)"
          ></span>
          <span
            [ngClass]="{
              disabled: currentIndex == pageNumber || pageNumber == 0
            }"
            (click)="nextPage()"
            class="next"
            >&raquo;
            </span>
        </div>
        <div class="text-center">
          <div class="two-btn">
            <div class="display">
              <a (click)="back()" class="btn-grey">
                {{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>