import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { DatePipe } from '@angular/common';
import { IDatePickerConfig } from 'ng2-date-picker';
import moment from 'moment';
@Component({
  selector: 'app-distribution-list',
  templateUrl: './distribution-list.component.html',
  styleUrls: ['./distribution-list.component.scss'],
})
export class DistributionListComponent implements OnInit {
  public AcquiredCompanyName = '';
  public AppDate_1;
  public SearchDate = '';
  public customCheckbox1: boolean = false;

  filteredItems = [];
  pages: number;
  pageSize: number = 10;
  pageNumber: number = 0;
  currentIndex: number = 1;
  items = [];
  items1 = [];
  pagesIndex: Array<number>;
  pageStart: number = 1;
  public st = '2'
  public pages1;
  public management_company_code;
  public management_company_name;
  public csv1;
  public Today;
  public today_date;
  public AcquiredInfo;
  public acquired_data = '';
  constructor(
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {
  }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-distribution-list';
    this.widthChanger('main-header', 360);
    this.spinner.show();
    this.Today = new Date();
    var newDate = moment().subtract(0, 'days');
    this.AppDate_1 = newDate;
    this.SearchDate = this.datepipe.transform(this.AppDate_1, 'yyyyMM');
    this.rest.Get_WFAccount(this.data.uuid).subscribe(
      (res) => {
        this.management_company_code = res.data.management_company_code;
        this.management_company_name = res.data.management_company_name;
        const body = {
          data: {
            user_uuid: this.data.uuid,
            management_company_code: this.management_company_code,
            acquired_code: this.AcquiredCompanyName,
            search_date: this.SearchDate
          }
        };
        console_log(body);
        this.rest.Get_DivisionList(this.data.uuid, this.management_company_code, this.AcquiredCompanyName, this.SearchDate).subscribe(
          (res) => {
            this.filteredItems = res.data.division_list;
            console_log(res);
            this.pageSize = this.filteredItems.length;
            this.currentIndex = 1;
            this.pageStart = 1;
            this.pages = 6;

            this.pageNumber = parseInt(
              '' + this.filteredItems.length / this.pageSize
            );
            if (this.filteredItems.length % this.pageSize != 0) {
              this.pageNumber++;
            }
            if (this.pageNumber < this.pages) {
              this.pages = this.pageNumber;
            }
            this.refreshItems();
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
          }
        );
      },
      (err) => {
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );
    this.rest.Get_AcquiredMaster(this.data.uuid).subscribe(
      (res) => {
        this.AcquiredInfo = res.data.AcquiredInfo;
        console_log(res);
      },
      (err) => {
        console_log(err);
      }
    );
  }
  parse(e) {
    if (e == 0 || e == '' || e == NaN) {
      e = '';
    } else {
      return parseFloat(e).toLocaleString('en');
    }
  }
  parse1(e) {
    return parseFloat(e).toLocaleString('en');
  }
  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i] as HTMLElement;
      header.style.minWidth = width.toString() + 'px';
    }
  }

  Search() {
    this.spinner.show();
    var acquired_code = this.acquired_data.split(":");
    if (this.AppDate_1) {
      this.SearchDate = this.datepipe.transform(this.AppDate_1, 'yyyyMM');
    }
    if (acquired_code[0] == undefined) {
      acquired_code[0] = '';
    }
    const body = {
      data: {
        user_uuid: this.data.uuid,
        management_company_code: this.management_company_code,
        acquired_code: acquired_code[0],
        search_date: this.SearchDate
      }
    };
    console_log(body);
    this.rest.Get_DivisionList(this.data.uuid, this.management_company_code, acquired_code[0], this.SearchDate).subscribe(
      (res) => {
        this.filteredItems = res.data.division_list;
        console_log(res);
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageSize = this.filteredItems.length;
        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
  Breakdown(id) {
    if (id == true) {
      const th = document.getElementsByClassName('th2');
      for (let i = 0; i < th.length; i++) {
        const th2 = th[i] as HTMLElement;
        th2.style.paddingTop = '28px';
      }
    } else {
      const th = document.getElementsByClassName('th2');
      for (let i = 0; i < th.length; i++) {
        const th2 = th[i] as HTMLElement;
        th2.style.paddingTop = '6px';
      }
    }
  }
  csv() {
    const bodyHC01_1 = {
      acquired_name: "獲得企業名",
      shop_name: "店舗名",
      head_amount: "分配本部",
      acquired_amount: "獲得金",
      acquired_member_total: "獲得人数",
      shop_amount: "利用料",
      shop_member_total: "利用人数",
      before_amount: "分配前",
      total_amount: "合計",
    }
    const bodyHC01_2 = {
      acquired_name: "獲得企業名",
      shop_name: "店舗名",
      head_amount: "分配本部",
      acquired_amount: "獲得金",
      acquired_member_total: "獲得人数",
      acquired_member_A: "獲得人数内訳(法人A)",
      acquired_member_B: "獲得人数内訳(法人B)",
      acquired_member_C: "獲得人数内訳(法人C)",
      shop_amount: "利用料",
      shop_member_total: "利用人数",
      shop_member_A: "利用人数内訳(法人A)",
      shop_member_B: "利用人数内訳(法人B)",
      shop_member_C: "利用人数内訳(法人C)",
      before_amount: "分配前",
      total_amount: "合計",
    }
    const body1 = {
      acquired_name: "獲得企業名",
      shop_name: "店舗名",
      acquired_amount: "獲得金",
      acquired_member_total: "獲得人数",
      shop_amount: "利用料",
      shop_member_total: "利用人数",
      before_amount: "分配前",
      total_amount: "合計",
    }
    const body2 = {
      acquired_name: "獲得企業名",
      shop_name: "店舗名",
      acquired_amount: "獲得金",
      acquired_member_total: "獲得人数",
      acquired_member_A: "獲得人数内訳(法人A)",
      acquired_member_B: "獲得人数内訳(法人B)",
      acquired_member_C: "獲得人数内訳(法人C)",
      shop_amount: "利用料",
      shop_member_total: "利用人数",
      shop_member_A: "利用人数内訳(法人A)",
      shop_member_B: "利用人数内訳(法人B)",
      shop_member_C: "利用人数内訳(法人C)",
      before_amount: "分配前",
      total_amount: "合計",
    }
    var csvItems = this.filteredItems;
    for (let i = 0; i < csvItems.length; i++) {
      if (csvItems[i].before_amount == '0') {
        csvItems[i].before_amount = '0'
      }
      if (csvItems[i].total_amount == '0') {
        csvItems[i].total_amount = '0'
      }
    }
    const replacer = (key, value) => value === null || value === 0 ? '' : value
    if (this.customCheckbox1) {
      console_log('内訳表示 == TRUE')
      if (this.management_company_code == 'HC01') {
        console_log('management_company_code == HC01')
        const header = Object.keys(bodyHC01_2);
        const headerValue = Object.values(bodyHC01_2);
        this.csv1 = csvItems.map(row => header.map(fieldName =>
          JSON.stringify(row[fieldName], replacer)).join(','));
        this.csv1.unshift(headerValue.join(','));
      } else {
        console_log('management_company_code != HC01')
        const header = Object.keys(body2);
        const headerValue = Object.values(body2);
        this.csv1 = csvItems.map(row => header.map(fieldName =>
          JSON.stringify(row[fieldName], replacer)).join(','));
        this.csv1.unshift(headerValue.join(','));
      }
    } else {
      console_log('内訳表示 == FALSE')
      if (this.management_company_code == 'HC01') {
        console_log('management_company_code == HC01')
        const header = Object.keys(bodyHC01_1);
        const headerValue = Object.values(bodyHC01_1);
        this.csv1 = csvItems.map(row => header.map(fieldName =>
          JSON.stringify(row[fieldName], replacer)).join(','));
        this.csv1.unshift(headerValue.join(','));
      } else {
        console_log('management_company_code != HC01')
        const header = Object.keys(body1);
        const headerValue = Object.values(body1);
        this.csv1 = csvItems.map(row => header.map(fieldName =>
          JSON.stringify(row[fieldName], replacer)).join(','));
        this.csv1.unshift(headerValue.join(','));
      }
    }
    this.csv1 = this.csv1.join('\r\n');

    // Create link and download

    this.Today = new Date();
    this.today_date = this.datepipe.transform(this.Today, 'yyyyMMddHHmmss');
    var a = '分配表_';
    var download_name;
    download_name = a + this.today_date;
    var link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(this.csv1));
    link.setAttribute('download', download_name + '.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  config: IDatePickerConfig = {
    format: 'yyyy-MM',
    yearFormat: 'yyyy' + '年',
    disableKeypress: true,
    onOpenDelay: 0,
    closeOnSelect: true,
    monthFormat: 'MM',
    monthBtnFormat: 'MM' + '月',
  }
  GoToCurrent(event) {
    event = moment().subtract(0, 'days');
    this.AppDate_1 = event;
  }
}