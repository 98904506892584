<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'DETAILS.TITLE' | translate }}</h3>
  <div class="card-body">
    <p class="left-border header-text2">
      {{ 'DETAILS.TITLE_TEXT_1' | translate }}
    </p>
  </div>
  <section class="content">
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.BEFORE_DEST' | translate }}/{{
              'DETAILS.AFTER_DEST' | translate
            }}
          </h3>
        </div>
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="Corporate_Status == '1'">
                {{ 'DETAILS.BEFORE_DEST' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporate_Status == '2'">
                {{ 'DETAILS.AFTER_DEST' | translate }}
              </h3>
              <h3 class="card-title long-text" *ngIf="Corporate_Status == '3'">
                {{ 'DETAILS.OTHERS_DEST' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.COMPANY_NAME_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title long-text">{{ Company_Name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest"></div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 style="margin-top: -25px" class="card-title long-text">
                {{ Company_Name_Kana }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.ADDRESS_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">
                {{ Post_Code }}<br />{{ Address_1 }}<br />
                {{ Address_2 }}{{ Address_3 }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.TELEPHONE_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ TelePhone }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 5 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.CHARGER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
              <h3 class="card-title display">{{ Last_Name }}</h3>
              <h3 class="card-title" style="padding-left: 18px">{{ Name }}</h3>
            </div>
          </div>
          <div class="col-12">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
              <h3 class="card-title display">{{ Last_Name_Kana }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ Name_Kana }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 6 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.DEPARTMENT_POSITION_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">
                {{ Staff_Post }}&ensp;{{ Staff_Position }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 7 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.USAGE_HISTORY_POSITION_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="History_Flg == '1'">
                {{ 'CONTRACT.USE_USED_HISTORY_RADIO_BUTTON' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="History_Flg == '0'">
                {{ 'CONTRACT.DON_T_USE_USED_HISTORY_RADIO_BUTTON' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 8 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.COMPANY_FORM_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="Corporation_Form == '1'">
                {{ 'CONTRACT.CONTRACTED_COMPANY_EMPLOYEE' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporation_Form == '2'">
                {{ 'CONTRACT.HEALTH_INSURANCE' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 9 -->
    <!-- <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.COMPANY_NUMBER_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Corporation_No }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 10 PDF Button -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.REGESTRATION_LAYER_COLUMN' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <a class="btn-pdf pdf-text" (click)="pdf_download()">
                <i class="nav-icon pdf"></i>
                {{ 'DETAILS.REGISTRATION_COPY_PDF_BUTTON' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 11 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.ACQUIRED_COMPANY_CODE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Acquired_Company_Code }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 12 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.ACQUIRED_COMPANY_NAME' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Acquired_Company_Name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 13 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.APPLY_DATE_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">
                {{ Application_Date }}&ensp;{{ Application_Time }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 14 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.EXAMINATION_STATUS_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="Judge_Status == '01'">
                {{ 'CONTRACT_APP_LIST.CHECKBOX_1' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Judge_Status == '02'">
                {{ 'CONTRACT_APP_LIST.CHECKBOX_2' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Judge_Status == '03'">
                {{ 'CONTRACT_APP_LIST.CHECKBOX_5' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Judge_Status == '04'">
                {{ 'CONTRACT_APP_LIST.CHECKBOX_4' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Judge_Status == '05'">
                {{ 'CONTRACT_APP_LIST.CHECKBOX_3' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Judge_Status == '99'">
                {{ 'CONTRACT_APP_LIST.CHECKBOX_6' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Judge_Status == '90'">
                {{ 'CONTRACT_APP_LIST.CHECKBOX_7' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Judge_Status == '  '"></h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 15 -->
    <div class="card-body" *ngIf="Judge_Status == '03' || Judge_Status == '99'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.NG_REASON_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Judge_Ng_Cause }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 16 -->
    <div class="card-body" *ngIf="Judge_Status == '03'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.DISPLAY_NG_REASON_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Judge_Disp_Cause }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 17 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.MONTHLY_FEE_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Fees | number :'3.0-0'}}{{ 'DETAILS.CIRCLE_TEXT' | translate }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 18 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.REMARKS_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Note }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Button -->
    <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="back()" class="btn-grey">
            {{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <div class="text-center">
      <div class="two-btn">
        <div class="display" *ngIf="wf_contract_apply_edit && wf_contract_apply_detail">
          <a (click)="ExaminationChange()" class="btn-log">
            {{ 'DETAILS.EXAMINATION_BUTTON' | translate }}
          </a>
        </div>
        <div class="display" *ngIf="wf_contract_apply_modify && wf_contract_apply_detail">
          <a (click)="ContractChange()" class="btn-log">
            {{ 'DETAILS.CONTRACT_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- button end -->
  </section>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>
