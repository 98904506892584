<div style="position: absolute; top: 0; left: 0; width: 100%">
  <nav
    class="navbar navbar-expand navbar-white navbar-light"
    style="margin-left: 0px"
  >
    <a [routerLink]="['/wf-login']">
      <img src={{logoUrl}} alt="AdminLTE Logo" class="brand-image"
    /></a>
  </nav>
</div>
<div class="login-box">
  <div class="login-logo">
    <i class="blackuser"></i>
    <p style="margin-top: -6px" class="title-login">
      {{ 'RESET_PASSWORD.TITLE' | translate }}
    </p>
  </div>
  <div class="text-center" style="padding-top: 24px">
    <p>
      {{ 'RESET_PASSWORD.RESET_PASSWORD_TEXT_1' | translate }}<br />{{
        'RESET_PASSWORD.RESET_PASSWORD_TEXT_2' | translate }}
    </p>
  </div>
  <form [formGroup]="registerForm" (ngSubmit)="next()">
    <div class="input-group mb-3" style="margin-top: 24px">
      <div class="input-group-append">
        <div class="input-group-text">
          <span class="ls lock"></span>
        </div>
      </div>
      <input
        formControlName="emailSend"
        type="email"
        class="form-control"
        placeholder="{{ 'RESET_PASSWORD.MAIL_ADDRESS_PLACEHOLDER' | translate }}"
        [(ngModel)]="emailSend"
        (ngModelChange)="onChange()"
      />
    </div>
    <div class="text-center">
      <div class="text1 text-center" style="height: 34px">
        <p id="errormsg"></p>
      </div>
      <div class="display" style="margin-top: 6px">
        <a (click)="next()" class="btn-log">
          {{ 'RESET_PASSWORD.RESET_PASSWORD_BUTTON' | translate }}
        </a>
      </div>
    </div>
  </form>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>