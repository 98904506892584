<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'MY_ACCOUNT.TITLE' | translate }}</h3>
  
  <div class="card-body">
    <p class="left-border header-text2">{{ 'MY_ACCOUNT.TITLE_TEXT_1' | translate }}</p>
    <p class="card-title">{{ 'MY_ACCOUNT.MAIL_CHANGE_TITLE_TEXT_1' | translate }}<br>{{ 'MY_ACCOUNT.MAIL_CHANGE_TITLE_TEXT_2' | translate }}</p>
  </div>
  <div class="text-center">
      <div class="login-box" style="margin: auto;">
        <div class="input-group mb-3">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="mail nav-icon ls"></span>
            </div>
          </div>
          <input
            type="email"
            class="form-control"
            placeholder="{{ 'MY_ACCOUNT.MAIL_ADDRESS_PLACEHOLDER' | translate }}"
            name="New_Mail"
            [(ngModel)]="New_Mail"
          />
        </div>
        <div class="text-center">
          <div class="text1 text-center" style="height: 34px">
            <p style="display: none;" id="error"></p>
          </div>
          <div class="display" style="margin-top: 6px">
            <a (click)="send()" class="btn-log">
              {{ 'MY_ACCOUNT.SEND_BUTTON' | translate }}
            </a>
          </div>
        </div>
      </div>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>