<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'ACCOUNT_MANAGEMENT.TITLE' | translate }}</h3>
  <div class="text-center">
    <div class="finish text-center">
      {{ 'ACCOUNT_ISSUE.TEXT_FINISH_ISSUED_ACCOUNT' | translate }}
    </div>
    <div class="finish2 text-center">
      {{ 'ACCOUNT_ISSUE.FINISH_TEXT' | translate }}
    </div>
    <div class="text-center">
      <div class="col-12">
        <div class="display finish2">
          <a (click)="back()" class="btn-grey">
            {{ 'ACCOUNT_ISSUE.BACK_TO_ACCOUNT_ISSUE_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>     
</div>