<nav class="main-header navbar navbar-expand navbar-white navbar-light" style="margin-left: 0px;">
  <ul class="navbar-nav d-block d-sm-block d-md-block d-lg-none d-xl-none">
    <li class="nav-item">
      <a class="nav-link" (click)="toggleMenuSidebar.emit()" role="button"
        ><i class="fas fa-bars"></i
      ></a>
    </li>
  </ul>
  <a [routerLink]="['/']">
  <img
      src={{logoUrl}}
      alt="AdminLTE Logo"
      class="brand-image"
    /></a>
  <ul class="navbar-nav ml-auto" *ngIf="wf_myaccount_edit">
    <li class="nav-item">
      <a
        class="nav-link"
        data-widget="control-sidebar"
        data-slide="true"
        [routerLink]="['wf-myaccount-edit']"
        role="button"
        style="padding-right: 0px;"
      >
        <i class="user"></i>
      </a>
    </li>
    <li class="nav-item">
      <h1
        [routerLink]="['wf-myaccount-edit']"
        class="text"
      >
      {{CompanyName}}<br>
      {{data.lastName}}&nbsp;&nbsp;{{data.firstName}}&nbsp;&nbsp;{{ 'COMMON.MR' | translate }}
      </h1>
    </li>
  </ul>
</nav>
