import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-resend-comp',
  templateUrl: './resend-comp.component.html',
  styleUrls: ['./resend-comp.component.scss'],
})
export class ResultResendCompComponent implements OnInit{
  constructor(
    private router: Router,
    public data: DataProvider,
    private route: ActivatedRoute,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-contract-list';
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          
          header.style.minWidth = width.toString() + 'px';
      }
  }
  
  back() {
    this.router.navigate(['/wf-contract-list']);
  }
}
