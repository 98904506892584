<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'DETAILS.TITLE' | translate }}</h3>
  <div class="text-center">
    <div class="text-center finish">
      {{ 'CONTRACT_CONFIRM.TITLE_TEXT_1' | translate }}<br>{{ 'CONTRACT_CONFIRM.TITLE_TEXT_2' | translate }}
    </div>
    <div class="text-center">
      <div class="col-12">
        <div class="display finish2">
          <a (click)="back()" class="btn-grey">
            {{ 'CONTRACT_CONFIRM.BACK_TO_CONTRACT_APPLICATION_LIST_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>     
</div>