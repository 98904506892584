<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'DETAILS.TITLE' | translate }}</h3>

  <section class="content" id="ExaminationEdit">
    <div class="card-body">
      <p class="left-border header-text2">
        {{ 'EXAMINATION.TITLE_TEXT_1' | translate }}
      </p>
      <p class="header-text-width">{{ 'EXAMINATION.TITLE_TEXT_2' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.BEFORE_DEST' | translate }}/{{
              'DETAILS.AFTER_DEST' | translate
            }}
          </h3>
        </div>
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="Corporate_Status == '1'">
                {{ 'DETAILS.BEFORE_DEST' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporate_Status == '2'">
                {{ 'DETAILS.AFTER_DEST' | translate }}
              </h3>
              <h3 class="card-title long-text" *ngIf="Corporate_Status == '3'">
                {{ 'DETAILS.OTHERS_DEST' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.COMPANY_NAME_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title long-text">{{ Company_Name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest"></div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 style="margin-top: -25px" class="card-title long-text">
                {{ Company_Name_Kana }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.ADDRESS_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">
                {{ Post_Code }}<br />{{ Address_1 }}<br />
                {{ Address_2 }}{{ Address_3 }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 4 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.TELEPHONE_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ TelePhone }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 5 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.CHARGER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
              <h3 class="card-title display">{{ Last_Name }}</h3>
              <h3 class="card-title" style="padding-left: 18px">{{ Name }}</h3>
            </div>
          </div>
          <div class="col-12">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
              <h3 class="card-title display">{{ Last_Name_Kana }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ Name_Kana }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 6 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.DEPARTMENT_POSITION_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">
                {{ Staff_Post }}&ensp;{{ Staff_Position }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 7 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.USAGE_HISTORY_POSITION_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="History_Flg == '1'">
                {{ 'CONTRACT.USE_USED_HISTORY_RADIO_BUTTON' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="History_Flg == '0'">
                {{ 'CONTRACT.DON_T_USE_USED_HISTORY_RADIO_BUTTON' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 8 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.COMPANY_FORM_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="Corporation_Form == '1'">
                {{ 'CONTRACT.CONTRACTED_COMPANY_EMPLOYEE' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporation_Form == '2'">
                {{ 'CONTRACT.HEALTH_INSURANCE' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 9 -->
    <!-- <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.COMPANY_NUMBER_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Corporation_No }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 10 PDF Button -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.REGISTRATION_COPY_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <a class="btn-pdf pdf-text" (click)="pdf_download()">
                <i class="nav-icon pdf"></i>
                {{ 'DETAILS.REGISTRATION_COPY_PDF_BUTTON' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 11 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.ACQUIRED_COMPANY_CODE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Acquired_Company_Code }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 12 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.ACQUIRED_COMPANY_NAME' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Acquired_Company_Name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 13 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.EXAMINATION_STATUS_DEST' | translate }}
          </h3>
        </div>
        <span class="badge badge-info">{{
          'COMMON.COMMON_REQUIRE_LABEL_1' | translate
        }}</span>
        <div class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-6 col-meaning">
          <div class="col-12 col-sm-12 col-md-12 col-xl-12">
            <!-- 1 -->
            <div class="form-group col-11">
              <div
                class="custom-control custom-radio"
                (click)="selectedRadios('01')"
                *ngIf="Judge_Status == '01'"
              >
                <input
                  class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="radio"
                  id="r1"
                  name="customRadio"
                  checked
                />
                <label for="r1" class="custom-control-label ftext">{{
                  'CONTRACT_APP_LIST.CHECKBOX_1' | translate
                }}</label>
              </div>
              <div
                class="custom-control custom-radio"
                (click)="selectedRadios('01')"
                *ngIf="Judge_Status != '01'"
              >
                <input
                  class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="radio"
                  id="r1"
                  name="customRadio"
                />
                <label for="r1" class="custom-control-label ftext">{{
                  'CONTRACT_APP_LIST.CHECKBOX_1' | translate
                }}</label>
              </div>
            </div>
            <!-- 2 -->
            <div class="form-group col-12">
              <div
                class="custom-control custom-radio"
                (click)="selectedRadios('02')"
                *ngIf="Judge_Status == '02'"
              >
                <input
                  class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="radio"
                  id="r2"
                  name="customRadio"
                  checked
                />
                <label for="r2" class="custom-control-label ftext">{{
                  'CONTRACT_APP_LIST.CHECKBOX_2' | translate
                }}</label>
              </div>
              <div
                class="custom-control custom-radio"
                (click)="selectedRadios('02')"
                *ngIf="Judge_Status != '02'"
              >
                <input
                  class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="radio"
                  id="r2"
                  name="customRadio"
                />
                <label for="r2" class="custom-control-label ftext">{{
                  'CONTRACT_APP_LIST.CHECKBOX_2' | translate
                }}</label>
              </div>
            </div>
            <!-- 3 -->
            <div class="form-group col-12">
              <div
                class="custom-control custom-radio"
                (click)="selectedRadios('05')"
                *ngIf="Judge_Status == '05'"
              >
                <input
                  class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="radio"
                  id="r3"
                  name="customRadio"
                  checked
                />
                <label for="r3" class="custom-control-label ftext">{{
                  'CONTRACT_APP_LIST.CHECKBOX_3' | translate
                }}</label>
              </div>
              <div
                class="custom-control custom-radio"
                (click)="selectedRadios('05')"
                *ngIf="Judge_Status != '05'"
              >
                <input
                  class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="radio"
                  id="r3"
                  name="customRadio"
                />
                <label for="r3" class="custom-control-label ftext">{{
                  'CONTRACT_APP_LIST.CHECKBOX_3' | translate
                }}</label>
              </div>
            </div>
            <!-- 4 -->
            <div class="form-group col-12">
              <div
                class="custom-control custom-radio"
                (click)="selectedRadios('04')"
                *ngIf="Judge_Status == '04'"
              >
                <input
                  class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="radio"
                  id="r4"
                  name="customRadio"
                  checked
                />
                <label for="r4" class="custom-control-label ftext">{{
                  'CONTRACT_APP_LIST.CHECKBOX_4' | translate
                }}</label>
              </div>
              <div
                class="custom-control custom-radio"
                (click)="selectedRadios('04')"
                *ngIf="Judge_Status != '04'"
              >
                <input
                  class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="radio"
                  id="r4"
                  name="customRadio"
                />
                <label for="r4" class="custom-control-label ftext">{{
                  'CONTRACT_APP_LIST.CHECKBOX_4' | translate
                }}</label>
              </div>
            </div>
            <!-- 5 -->
            <div class="form-group col-12 margin">
              <div
                class="custom-control custom-radio"
                (click)="selectedRadios('03')"
                *ngIf="Judge_Status == '03'"
              >
                <input
                  class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="radio"
                  id="r5"
                  name="customRadio"
                  checked
                />
                <label for="r5" class="custom-control-label ftext line">{{
                  'CONTRACT_APP_LIST.CHECKBOX_5' | translate
                }}<br><span class="sent">{{'COMMON.EMAIL_CAN_BE_SENT' | translate}}</span></label>
              </div>
              <div
                class="custom-control custom-radio"
                (click)="selectedRadios('03')"
                *ngIf="Judge_Status != '03'"
              >
                <input
                  class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="radio"
                  id="r5"
                  name="customRadio"
                />
                <label for="r5" class="custom-control-label ftext line">{{
                  'CONTRACT_APP_LIST.CHECKBOX_5' | translate
                }}<br><span class="sent">{{'COMMON.EMAIL_CAN_BE_SENT' | translate}}</span></label>
              </div>
            </div>
            <!-- 6 -->
            <div class="form-group col-12 margin">
              <div
                class="custom-control custom-radio"
                (click)="selectedRadios('99')"
                *ngIf="Judge_Status == '99'"
              >
                <input
                  class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="radio"
                  id="r6"
                  name="customRadio"
                  checked
                />
                <label for="r6" class="custom-control-label ftext line">{{
                  'CONTRACT_APP_LIST.CHECKBOX_6' | translate
                }}<br><span class="sent">{{'COMMON.EMAIL_CAN_BE_SENT' | translate}}</span></label>
              </div>
              <div
                class="custom-control custom-radio"
                (click)="selectedRadios('99')"
                *ngIf="Judge_Status != '99'"
              >
                <input
                  class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="radio"
                  id="r6"
                  name="customRadio"
                />
                <label for="r6" class="custom-control-label ftext line">{{
                  'CONTRACT_APP_LIST.CHECKBOX_6' | translate
                }}<br><span class="sent">{{'COMMON.EMAIL_CAN_BE_SENT' | translate}}</span></label>
              </div>
            </div>
            <!-- 7 -->
            <div class="form-group col-12">
              <div
                class="custom-control custom-radio"
                (click)="selectedRadios('90')"
                *ngIf="Judge_Status == '90'"
              >
                <input
                  class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="radio"
                  id="r7"
                  name="customRadio"
                  checked
                />
                <label for="r7" class="custom-control-label ftext line">{{
                  'CONTRACT_APP_LIST.CHECKBOX_7' | translate
                }}<br><span class="sent">{{'COMMON.EMAIL_CAN_BE_SENT' | translate}}</span></label>
              </div>
              <div
                class="custom-control custom-radio"
                (click)="selectedRadios('90')"
                *ngIf="Judge_Status != '90'"
              >
                <input
                  class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="radio"
                  id="r7"
                  name="customRadio"
                />
                <label for="r7" class="custom-control-label ftext line">{{
                  'CONTRACT_APP_LIST.CHECKBOX_7' | translate
                }}<br><span class="sent">{{'COMMON.EMAIL_CAN_BE_SENT' | translate}}</span></label>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
      </div>
    </div>
    <!-- 14 -->
    <div class="card-body" *ngIf="Judge_Status == '03' || Judge_Status == '99'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.NG_REASON_DEST' | translate }}</h3>
        </div>
        <span class="badge badge-info">{{
          'COMMON.COMMON_REQUIRE_LABEL_1' | translate
        }}</span>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 col-meaning">
          <div class="col-12">
            <div class="col-12">
              <div class="form-group">
                <textarea
                  name="Judge_Ng_Cause"
                  [(ngModel)]="Judge_Ng_Cause"
                  class="form-control h"
                  rows="5"
                  placeholder="Enter ..."
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 15 -->
    <div class="card-body" *ngIf="Judge_Status == '03'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.DISPLAY_NG_REASON_DEST' | translate }}
          </h3>
        </div>
        <span class="badge badge-info">{{
          'COMMON.COMMON_REQUIRE_LABEL_1' | translate
        }}</span>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 col-meaning">
          <div class="col-12">
            <div class="col-12">
              <div class="form-group">
                <textarea
                  name="Judge_Disp_Cause"
                  [(ngModel)]="Judge_Disp_Cause"
                  class="form-control h"
                  rows="5"
                  cols="50"
                  placeholder="Enter ..."
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 16 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.MONTHLY_FEE_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Fees | number :'3.0-0'}}{{ 'DETAILS.CIRCLE_TEXT' | translate }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>                        
    <!-- 17 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.REMARKS_DEST' | translate }}</h3>
        </div>
        <span class="badge badge-secondary">{{
          'COMMON.COMMON_REQUIRE_LABEL_2' | translate
        }}</span>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 col-meaning">
          <div class="col-12">
            <div class="col-12">
              <div class="form-group">
                <textarea
                  name="Note"
                  [(ngModel)]="Note"
                  class="form-control h"
                  rows="5"
                  cols="50"
                  placeholder="Enter ..."
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Button -->
    <!-- <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="back(1)" class="btn-grey"
            >{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="Examination()" class="btn-pink"
            >{{ 'EXAMINATION.REGISTER_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div> -->
    <!-- Buttons -->
    <div class="text-center">
      <div class="text1 text-center" style="height: 34px">
        <p style="display: none;" id="error"></p>
      </div>
      <div class="two-btn">
        <div class="display">
          <a (click)="back(1)" class="btn-grey"
          >{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="Examination()" class="btn-pink"
            >{{ 'EXAMINATION.REGISTER_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- button end -->
  </section>
  <!-- Section 2 -->
  <section class="content" id="ExaminationConfirm" style="display: none">
    <!-- header texts -->
    <div class="card-body">
      <p class="left-border header-text2">
        {{ 'EXAMINATION.TITLE_TEXT_1' | translate }}
      </p>
      <p class="header-text-width">{{ 'EXAMINATION.TITLE_TEXT_3' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.BEFORE_DEST' | translate }}/{{
              'DETAILS.AFTER_DEST' | translate
            }}
          </h3>
        </div>
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="Corporate_Status == '1'">
                {{ 'DETAILS.BEFORE_DEST' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporate_Status == '2'">
                {{ 'DETAILS.AFTER_DEST' | translate }}
              </h3>
              <h3 class="card-title long-text" *ngIf="Corporate_Status == '3'">
                {{ 'DETAILS.OTHERS_DEST' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.COMPANY_NAME_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title long-text">{{ Company_Name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest"></div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 style="margin-top: -25px" class="card-title long-text">
                {{ Company_Name_Kana }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.ADDRESS_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">
                {{ Post_Code }}<br />{{ Address_1 }}<br />
                {{ Address_2 }}{{ Address_3 }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.TELEPHONE_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ TelePhone }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 5 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.CHARGER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
              <h3 class="card-title display">{{ Last_Name }}</h3>
              <h3 class="card-title" style="padding-left: 18px">{{ Name }}</h3>
            </div>
          </div>
          <div class="col-12">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
              <h3 class="card-title display">{{ Last_Name_Kana }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ Name_Kana }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 6 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.DEPARTMENT_POSITION_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">
                {{ Staff_Post }}&ensp;{{ Staff_Position }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 7 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.USAGE_HISTORY_POSITION_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="History_Flg == '1'">
                {{ 'CONTRACT.USE_USED_HISTORY_RADIO_BUTTON' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="History_Flg == '0'">
                {{ 'CONTRACT.DON_T_USE_USED_HISTORY_RADIO_BUTTON' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 8 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.COMPANY_FORM_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="Corporation_Form == '1'">
                {{ 'CONTRACT.CONTRACTED_COMPANY_EMPLOYEE' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporation_Form == '2'">
                {{ 'CONTRACT.HEALTH_INSURANCE' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 9 -->
    <!-- <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.COMPANY_NUMBER_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Corporation_No }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 10 -->
    <!-- 10 PDF Button -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.REGISTRATION_COPY_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <a class="btn-pdf pdf-text" (click)="pdf_download()">
                <i class="nav-icon pdf"></i>
                {{ 'DETAILS.REGISTRATION_COPY_PDF_BUTTON' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 11 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.ACQUIRED_COMPANY_CODE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Acquired_Company_Code }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 12 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.ACQUIRED_COMPANY_NAME' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Acquired_Company_Name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 11 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.EXAMINATION_STATUS_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="Judge_Status == '01'">
                {{ 'CONTRACT_APP_LIST.CHECKBOX_1' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Judge_Status == '02'">
                {{ 'CONTRACT_APP_LIST.CHECKBOX_2' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Judge_Status == '03'">
                {{ 'CONTRACT_APP_LIST.CHECKBOX_5' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Judge_Status == '04'">
                {{ 'CONTRACT_APP_LIST.CHECKBOX_4' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Judge_Status == '05'">
                {{ 'CONTRACT_APP_LIST.CHECKBOX_3' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Judge_Status == '99'">
                {{ 'CONTRACT_APP_LIST.CHECKBOX_6' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Judge_Status == '90'">
                {{ 'CONTRACT_APP_LIST.CHECKBOX_7' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 12 -->
    <div class="card-body" *ngIf="Judge_Status == '03' || Judge_Status == '99'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.NG_REASON_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title">{{ Judge_Ng_Cause }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 13 -->
    <div class="card-body" *ngIf="Judge_Status == '03'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.DISPLAY_NG_REASON_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
              <h3 class="card-title">{{ Judge_Disp_Cause }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 14 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.MONTHLY_FEE_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">
                {{ Fees | number :'3.0-0'}}{{ 'DETAILS.CIRCLE_TEXT' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 15 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'DETAILS.REMARKS_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">
                <h3 class="card-title">{{ Note }}</h3>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 16 -->
    <div class="text-center about">
      <p>{{ 'DETAILS.TITLE_CENTER_TEXT' | translate }}</p>
    </div>
    <!-- Buttons -->
    <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="back(2)" class="btn-grey"
            >{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="ExaminationConfirm()" class="btn-pink"
            >{{ 'COMMON.COMMON_OKEY_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- button end -->
  </section>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>