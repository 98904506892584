import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { updateFunctionDeclaration } from 'typescript';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class AccountListComponent implements OnInit {
  public Headquarters = '本部';
  filteredItems = [];
  pages : number = 6;
  pageSize : number = 10;
  pageNumber : number = 0;
  currentIndex : number = 1;
  items = [];
  pagesIndex : Array<number>;
  pageStart : number = 1;
  public pages1;
  public wf_account_list_detail: boolean;
  
  constructor(
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,) {
  }
  public name = '';
  public department_shop = '';
  public mail_address = '';
  public user_auth_type = '';
  public user_auth_type20 = false;
  public user_auth_type21 = false;
  public user_auth_type22 = false;
  public user_auth_type23 = false;
  public user_auth_type24 = false;
  ngOnInit() {
    this.data.menuPageTitle = 'wf-account-list';
    this.spinner.show();
    const body = {
      data:{
        user_uuid: this.data.uuid,
        name: this.name,
        department_shop: this.department_shop,
        mail_address: this.mail_address,
        user_auth_type: this.user_auth_type
      }
    };
    console_log(body);
    this.rest.Get_WFAccountList(this.data.uuid, this.name, this.department_shop, this.mail_address, this.user_auth_type).subscribe(
      (res) => {
        console_log(res);
        this.filteredItems = res.data.accounts;
        if (this.filteredItems.length >= 500) {
          document.getElementById('limit500').style.display = 'block';
        } else {
          document.getElementById('limit500').style.display = 'none';
        }
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        this.pages1 = res.data;
        for (let i = 0; i < this.pages1.length; i++) {
          if (this.pages1[i].pagename == 'wf-account-list-detail') {
            console_log('wf-account-list-detail');
            if (this.pages1[i].auth == 'F' || this.pages1[i].auth == 'R') {
              this.wf_account_list_detail = true;
            }
            if (this.pages1[i].auth == 'D') {
              this.wf_account_list_detail = false;
            }
          }
        }
      },
      (err) => {
      }
    );
  }
  
  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }
  Search() {
    this.spinner.show();
    var a1, a2, a3, a4, a5;
    a1 = this.user_auth_type20 ? '20' : '';
    a2 = this.user_auth_type21 ? '21' : '';
    a3 = this.user_auth_type22 ? '22' : '';
    a4 = this.user_auth_type23 ? '23' : '';
    a5 = this.user_auth_type24 ? '24' : '';
    this.user_auth_type = a1 + a2 + a3 + a4 + a5;
    const body = {
      data:{
        user_uuid: this.data.uuid,
        name: this.name,
        department_shop: this.department_shop,
        mail_address: this.mail_address,
        user_auth_type: this.user_auth_type
      }
    };
    console_log(body);
    this.rest.Get_WFAccountList(this.data.uuid, this.name, this.department_shop, this.mail_address, this.user_auth_type).subscribe(
      (res) => {
        console_log(res);
        this.filteredItems = res.data.accounts;
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
  
  details(uuid) {
    this.router.navigate(['/wf-account-list-detail',{uuid: uuid}]);
  }
}
