import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-comp',
  templateUrl: './edit-comp.component.html',
  styleUrls: ['./edit-comp.component.scss'],
})
export class ModifyEditCompComponent implements OnInit {
  constructor(
    private router: Router,
    public data: DataProvider,
    private route: ActivatedRoute,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-contract-list';
    this.widthChanger('main-header', 360);
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
  back() {
    this.router.navigate(['/wf-contract-list']);
  }
}
