import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  public changePasswordForm: FormGroup;
  public error_code;
  public uuid;
  public logoUrl;
  constructor(
    private renderer: Renderer2,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.renderer.addClass(document.querySelector('app-root'), 'login-page');
    this.error_code = this.route.snapshot.paramMap.get('code');
    this.uuid = this.route.snapshot.paramMap.get('id');
    if(this.error_code != null){
      this.translate.get('ETITLE.' + this.error_code).subscribe((res) => (document.getElementById('err_title1').innerHTML = res));
      this.translate.get('ERROR.' + this.error_code).subscribe(
        (res) => {
          var UUID = /uuid/gi; 
          var error_str = res.replace(UUID, this.uuid);
          document.getElementById('err_text1').innerHTML = error_str + '<br>' + '('+this.error_code+')'
        }
      );
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page'
    );
  }
}
