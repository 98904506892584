<div style="position: absolute; top: 0; left: 0; width: 100%">
  <nav
    class="navbar navbar-expand navbar-white navbar-light"
    style="margin-left: 0px"
  >
    <a [routerLink]="['/wf-login']">
      <img
        src={{logoUrl}}
        alt="AdminLTE Logo"
        class="brand-image"
    /></a>
  </nav>
</div>
<div class="login-box" id="correct" style="display: none;">
  <div class="login-logo">
    <i class='correct'></i>
    <p style="margin-top: -6px;" class="title-login">{{ 'ACCOUNT_MANAGEMENT.ACTIVATION_IS_COMPLETE' | translate }}</p>
  </div>
</div>
<div class="login-box" id='error' style="display: none;">
  <div class="login-logo">
    <i class="warning"></i>
    <p id="err_title1" style="margin-top: -6px;" class="title-login error-text">{{ 'MY_ACCOUNT.EMAIL_ERROR_TEXT_1' | translate }}</p>
    <p id="err_text1" style="margin-top: -10px;" class="title-login error-text">{{ 'MY_ACCOUNT.EMAIL_ERROR_TEXT_2' | translate }}</p>
  </div>
</div>
