<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'POINT.EDIT_TITLE' | translate }}
  </h3>
  <div class="card-body">
    <p class="left-border header-text2">
      {{ 'POINT.EDIT_TITLE_1' | translate }}
    </p>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
        <h3 class="card-title">
          {{ 'POINT.MEMBERSHIP_PLACEHOLDER' | translate }}
        </h3>
      </div>
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-meaning">
        <div class="col-12">
          <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
            <h3 class="card-title long-text">{{ memberID }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
        <h3 class="card-title">
          {{ 'POINT.FULL_NAME' | translate }}
        </h3>
      </div>
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-meaning">
        <div class="col-12">
          <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
            <h3 class="card-title long-text">{{ memberName }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
        <h3 class="card-title">
          {{ 'POINT.GRANT_POINTS' | translate }}
        </h3>
      </div>
      <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="input-group" style="margin-left: 30px; width: 199px">
            <input
              type="text"
              class="form-control"
              id="Price"
              oncopy="return false;" onpaste="return false;" oncut="return false;"
              [(ngModel)]="Price"
              placeholder="1,000"
              maxlength="4"
              autocomplete="off"
              onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
            />
          </div>
          <div class="display">
            <h3 class="font">BP</h3>
          </div>
      </div>
    </div>
  </div>
  <div class="text-center" style="height: 34px">
    <p class="text1" style="display: none;" id="error"></p>
  </div>
  <div class="text-center">
    <div class="two-btn1">
      <div class="display confirm">
        <a (click)="confirm()" class="btn-pink"
          >{{ 'POINT.POINT_CONFIRMATION_BUTTON' | translate }}
        </a>
      </div>
    </div>
  </div>
  <div class="text-center">
    <div class="two-btn1">
      <div class="display">
        <a (click)="back()" class="btn-grey"
          >{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>