import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class AccountDeleteComponent implements OnInit {
  public uuid = '';
  public Last_Name = '';
  public First_Name = '';
  public Department_Shop = '';
  public Mail_Address = '';
  public User_Auth_Name = '';
  public understood = false
  public User_Auth;
  public AcquiredData;
  public StoreName;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    public data: DataProvider,
    private route: ActivatedRoute,
    public rest: CallapiService,
  ) { }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-account-list';
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.spinner.show();
    this.rest.Get_WFAccount(this.uuid).subscribe(
      (res) => {
        this.Last_Name = res.data.user_last_name;
        this.First_Name = res.data.user_first_name;
        this.Department_Shop = res.data.department_name;
        this.AcquiredData = res.data.management_company_code + ':' + res.data.management_company_name;
        this.Mail_Address = res.data.mail_address;
        this.StoreName = res.data.shop_name
        this.User_Auth_Name = res.data.user_auth_type;
        if(this.User_Auth_Name == 'システム管理者'){
          this.User_Auth = 'wf-sys-admin';
        }else if(this.User_Auth_Name == 'WF本部'){
          this.User_Auth = 'wf-corp-staff';
        }else if(this.User_Auth_Name == '直営店スタッフ'){
          this.User_Auth = 'wf-direct-staff';
        }else if(this.User_Auth_Name == '加盟店本部'){
          this.User_Auth = 'wf-fc-head';
        }else if(this.User_Auth_Name == '加盟店スタッフ'){
          this.User_Auth = 'wf-fc-staff';
        }
        console_log(res);
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );
  }
  back() {
    this.router.navigate(['/wf-account-list-detail', { uuid: this.uuid }]);
  }
  next() {
    const body = {
      data: {
        user_uuid: this.data.uuid,
        uuid: this.uuid
      }
    };
    console_log(body);
    this.spinner.show();
    if (this.understood) {
      this.rest.Delete_CognitoUser(this.data.uuid, this.uuid).subscribe(
        (res) => {
          console_log(res);
          this.spinner.hide();
          this.router.navigate(['/wf-account-list-delete-comp']);
        },
        (err) => {
          console_log(err)
          this.spinner.hide();
          this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
        }
      )
    } else {
      this.spinner.hide();
    }
  }
}
