import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-resend',
  templateUrl: './resend.component.html',
  styleUrls: ['./resend.component.scss'],
})
export class ResultResendComponent implements OnInit {
  public StartDate;
  public EndDate;
  public Mail;
  public Users;
  public Company_Number;
  public documentpdf;
  public uuid;
  public Corporation_Form;
  public Company_Name;
  public Company_Name_Kana;
  public Post_Code;
  public Address_1;
  public Address_2;
  public Address_3;
  public TelePhone;
  public Last_Name;
  public Name;
  public Last_Name_Kana;
  public Name_Kana;
  public Staff_Post;
  public Staff_Position;
  public History_Flg;
  public Corporate_Status;
  public Corporation_No;
  public Acquired_Company_Code;
  public Acquired_Company_Name;
  public Application_Date;
  public Application_Time;
  public Judge_Status;
  public Judge_Ng_Cause;
  public Judge_Disp_Cause;
  public Note;
  public ContractsFLG = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-contract-list';
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.spinner.show();
    this.rest.Get_ApplicationsId(this.data.uuid, this.uuid).subscribe(
      (res) => {
        console_log(res);
        this.Corporation_Form = res.data.corporation_form;
        this.Company_Name = res.data.company_name;
        this.Company_Name_Kana = res.data.company_name_kana;
        this.Post_Code = res.data.postcode;
        this.Address_1 = res.data.address_1;
        this.Address_2 = res.data.address_2;
        this.Address_3 = res.data.address_3;
        this.TelePhone = res.data.tel;
        this.Last_Name = res.data.staff_last_name;
        this.Name = res.data.staff_first_name;
        this.Last_Name_Kana = res.data.staff_last_name_kana;
        this.Name_Kana = res.data.staff_first_name_kana;
        this.Staff_Post = res.data.staff_post;
        this.Staff_Position = res.data.staff_position;
        this.History_Flg = res.data.history_flg;
        this.Corporate_Status = res.data.corporate_status;
        this.Corporation_No = res.data.corporation_no;
        this.Acquired_Company_Code = res.data.acquired_code;
        this.Acquired_Company_Name = res.data.acquired_name;
        this.Application_Date = res.data.app_date;
        this.Application_Time = res.data.app_time;
        this.Judge_Status = res.data.judge_state;
        this.Note = res.data.note;
        this.Judge_Ng_Cause = res.data.judge_ng_cause;
        this.Judge_Disp_Cause = res.data.judge_disp_cause;
        this.Mail = res.data.mail_address;
        this.ContractsFLG = res.data.contracts_flg;
        this.spinner.hide();
      },(err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
    this.widthChanger('main-header', 752);
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;

          header.style.minWidth = width.toString() + 'px';
      }
  }

  back() {
    this.router.navigate(['/wf-contract-list-detail',{ uuid: this.uuid }]);
  }
  send() {
    const body = {
      data:{
          user_uuid: this.data.uuid,
          corporation_no: this.Corporation_No,
          corporate_status: this.Corporate_Status,
          company_name: this.Company_Name,
          mail_address: this.Mail,
          staff_last_name: this.Last_Name,
          staff_first_name: this.Name
      }
    };
    console_log(body);
    this.spinner.show();
    this.rest.Send_ReStatus(this.data.uuid,
      this.Corporation_No,
      this.Corporate_Status,
      this.Company_Name,
      this.Mail,
      this.Last_Name,
      this.Name).subscribe(
      (res)=>{
        console_log(res);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-result-resend-comp']);
      },(err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
  pdf_download(){
    this.rest
      .Download_Regcertificates(this.data.uuid, this.uuid)
      .subscribe((res) => {
        if (res.code == 'S000001') {
          this.documentpdf = res.data.s3url;
          window.open(this.documentpdf, '_blank', '');
        }
      });
  }
  pdf_download_s3() {
    this.spinner.show();
    this.rest.Download_Contracts(this.data.uuid, this.uuid).subscribe(
        (res) => {
          if (res.code == 'S000001') {
            this.spinner.hide();
            window.open(res.data.s3url, '_blank', '');
          }
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
        }
      );
  }
}
