<div style="position: absolute;top: 0;left: 0;width: 100%;">
  <nav class="navbar navbar-expand navbar-white navbar-light" style="margin-left: 0px;">
    <a [routerLink]="['/wf-login']">
    <img
        src={{logoUrl}}
        alt="AdminLTE Logo"
        class="brand-image"
      /></a>
  </nav>
</div>
<div class="login-box">
  <div class="login-logo">
    <i class='blackuser'></i>
    <p style="margin-top: -6px;" class="title-login">{{ 'CHANGE_PASSWORD.TITLE' | translate }}</p>
  </div>
  <div class="ftext text-center" style="margin-top: 24px;">
    <p>{{ 'CHANGE_PASSWORD.PASSWORD_RESET_TEXT' | translate }}</p>
  </div>
</div>
<div class="login-box">
  <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
    <div class="input-group mb-3"  style="margin-top: 24px;">
      <div class="input-group-append">
        <div class="input-group-text">
          <span class="ls key"></span>
        </div>
      </div>
      <input
        formControlName="Mail_Code"
        type="text"
        class="form-control"
        placeholder="{{ 'CHANGE_PASSWORD.PASSWORD_TEMP_PLACEHOLDER' | translate }}"
        [(ngModel)]="Mail_Code"
        (ngModelChange)="onChange()"
        autocomplete="new-password"
        onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
        maxlength="6"
      />
    </div>
    <div class="input-group mb-3" style="margin-top: 40px;">
      <div class="input-group-append">
        <div class="input-group-text">
          <span class="ls key"></span>
        </div>
      </div>
      <input
        formControlName="Password_New"
        type="text"
        class="form-control pw"
        placeholder="{{ 'CHANGE_PASSWORD.PASSWORD_CHANGED_PLACEHOLDER' | translate }}"
        [(ngModel)]="Password_New"
        (ngModelChange)="onChange()"
        autocomplete="new-password"
      />
    </div>
    <div class="input-group mb-3" style="margin-top: -6px;">
      <div class="input-group-append">
        <div class="input-group-text">
          <span class="ls key"></span>
        </div>
      </div>
      <input
        formControlName="Password_New_2"
        type="text"
        class="form-control pw"
        placeholder="{{ 'CHANGE_PASSWORD.PASSWORD_CHANGED_CONFIRM_PLACEHOLDER' | translate }}"
        [(ngModel)]="Password_New_2"
        (ngModelChange)="onChange()"
        autocomplete="new-password"
      />
    </div>
    <div class="text-center">
      <div class="text1 text-center" style="height: 34px">
        <p id="errormsgchgpass"></p>
      </div>
      <div class="display" style="margin-top: 6px">
        <a (click)="changePassword()" class="btn-log">
          {{ 'CHANGE_PASSWORD.PASSWORD_CHANGE_BUTTON' | translate }}
        </a>
      </div>
    </div>
  </form> 
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>