import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-unpaid-list',
  templateUrl: './unpaid-list.component.html',
  styleUrls: ['./unpaid-list.component.scss'],
})
export class UnpaidListComponent implements OnInit {
  public AcquiredCode = '';
  public customCheckbox: boolean = true;
  public ClosedDate;
  public today
  public unpaid_only_flg = '';
  public AcquiredInfo;
  public acquired_data = '';

  filteredItems = [];
  pages: number;
  pageSize: number = 10;
  pageNumber: number = 0;
  currentIndex: number = 1;
  items = [];
  pagesIndex: Array<number>;
  pageStart: number = 1;
  public pages1;
  constructor(
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {
  }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-unpaid-list';
    this.spinner.show();
    this.widthChanger('main-header', 360);
    this.unpaid_only_flg = this.customCheckbox ? '1' : '0';
    const body = {
      data:{
        user_uuid: this.data.uuid,
        acquired_code: this.AcquiredCode,
        unpaid_only_flg: this.unpaid_only_flg,
      }
    };
    console_log(body);
    this.rest.Get_AcquiredMaster(this.data.uuid).subscribe(
      (res) => {
        this.AcquiredInfo = res.data.AcquiredInfo;
        console_log(res);
      },
      (err) => {
        console_log(err);
      }
    );
    this.rest.Get_UnpaidAcquired(this.data.uuid, this.AcquiredCode, this.unpaid_only_flg).subscribe(
      (res) => {
        this.filteredItems = res.data.unpaid_list;
        this.ClosedDate = res.cutoff_date;
        console_log(res);
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
  parse(e) {
    return parseFloat(e).toLocaleString('en');
  }
  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
  
  Search(){
    this.spinner.show();
    this.unpaid_only_flg = this.customCheckbox ? '1' : '0';
     var acquired_data = this.acquired_data.split(":");
     this.AcquiredCode = acquired_data[0];
     const body = {
      data:{
        user_uuid: this.data.uuid,
        acquired_code: this.AcquiredCode,
        unpaid_only_flg: this.unpaid_only_flg,
      }
    };
    console_log(body);
    this.rest.Get_UnpaidAcquired(this.data.uuid, this.AcquiredCode, this.unpaid_only_flg).subscribe(
      (res) => {
        this.filteredItems = res.data.unpaid_list;
        console_log(res);
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
  UnpaidCorporation(AcquiredCode, AcquiredName){
    this.data.AcquiredCode = AcquiredCode;
    this.data.AcquiredName = AcquiredName;
    this.router.navigate(['/wf-unpaid-corp-list']);
  }
}