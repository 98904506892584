<div style="position: absolute;top: 0;left: 0;width: 100%;">
  <nav class="navbar navbar-expand navbar-white navbar-light" style="margin-left: 0px;">
    <a [routerLink]="['/wf-login']">
    <img
        src="assets/img/fit365_logo.svg"
        alt="AdminLTE Logo"
        class="brand-image"
        src={{logoUrl}}
      /></a>
  </nav>
</div>
<div class="login-box">
  <div class="login-logo">
    <i class='warning'></i>
    <p id="err_title" style="margin-top: -6px;" class="title-login">{{ 'MY_ACCOUNT.EMAIL_ERROR_TEXT_1' | translate }}</p>
    <p id="err_text" style="margin-top: -10px;" class="title-login">{{ 'MY_ACCOUNT.EMAIL_ERROR_TEXT_2' | translate }}</p>
    </div>
</div>

<!-- Loader -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#f77fb1 " type="square-jelly-box" [fullScreen]="true">
  <p style="color: #f77fb1 "> {{ 'COMMON.LOADING' | translate }} </p>
</ngx-spinner>