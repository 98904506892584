import { Component, OnInit } from '@angular/core';
import { RESOURCE_CACHE_PROVIDER } from '@angular/platform-browser-dynamic';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BEA_POINT_HASH, MANUAL_PDF_FC, MANUAL_PDF_HC ,PT_HASH} from 'src/environments/environment';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-point-edit-conf',
  templateUrl: './point-edit-conf.component.html',
  styleUrls: ['./point-edit-conf.component.scss'],
})
export class PointEditConfComponent implements OnInit {
  public Price = '';
  public Point = '';
  public dateTime = '';
  public hash ='';
  public sCode = 'P10000000000';
  public sValue = 'パーソナルトレーナー受講';
  public type = 'pt';

  public transactionID = '';

  public lastNameKanji;
  public firstNameKanji;
  public lastNameKana;
  public firstNameKana;
  public Birthday;
  constructor(
    public data: DataProvider,
    public rest: CallapiService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-point';
    this.spinner.show();
    this.Point = this.data.Point;
    this.Price = this.data.Price;
    this.spinner.hide();
    this.widthChanger('main-header', 942);
  }
  dataValidation() {
    let err = false;
    let errTxt = '';
    if (this.Point == null || this.Point == '' || this.Point == '0') {
      err = true;
      this.translate
        .get('POINT_VALIDATION.ERROR_POINT')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      return false;
    }
    return true;
  }
  // parse(e){
  //   if(e == NaN){
  //     e = '';
  //   }else{
  //     return parseFloat(e).toLocaleString('en');
  //   }
  // }
  complete(){
    this.spinner.show();
    if (this.dataValidation() == false) {
      this.spinner.hide();
      return true;
    } else {
      this.dateTime = new Date().valueOf().toString();
      var string = this.data.memberID + this.dateTime + BEA_POINT_HASH;
      this.hashFunction(string).then(
        (res) => {
          this.hash = res;
          var point_hash_str = this.data.memberID + this.dateTime + PT_HASH;
          this.hashFunction(point_hash_str).then(
            (res) => {
              var point_hash = res;
              // 1
              const body = {
                memberID: this.data.memberID,
                datetime: this.dateTime,
                hash: point_hash,
                price: Number(this.Point)
              };
              console_log(body);

              this.rest.managePoint(this.data.memberID, this.dateTime, this.hash, this.data.ClubCode, this.data.ContractTypeCode, this.sCode, this.sValue, Number(this.Point)).subscribe(
                (res) => {
                  console_log(res);
                  if(res.result == 'OK'){
                    this.rest.pointNotification(this.data.memberID, this.dateTime, point_hash, this.type, Number(this.Point)).subscribe(
                      (res) => {
                        console_log(res);
                        if (res.result == 'OK') {
                          this.spinner.hide();
                          this.router.navigate(['/wf-point-edit-comp']);
                        } else {
                          this.spinner.hide()
                          this.router.navigate(['/wf-contract-err', { code: res.errorCode }]);
                        }
                      },
                      (err) => {
                        //2
                        console_log(err);
                        setTimeout(() => {
                          this.rest.pointNotification(this.data.memberID, this.dateTime, point_hash, this.type, Number(this.Point)).subscribe(
                            (res) => {
                              console_log(res);
                              if (res.result == 'OK') {
                                this.spinner.hide();
                                this.router.navigate(['/wf-point-edit-comp']);
                              } else {
                                this.spinner.hide()
                                this.router.navigate(['/wf-contract-err', { code: res.errorCode }]);
                              }
                            },
                            (err) => {
                              //3
                              console_log(err);
                              setTimeout(() => {
                                this.rest.pointNotification(this.data.memberID, this.dateTime, point_hash, this.type, Number(this.Point)).subscribe(
                                  (res) => {
                                    console_log(res);
                                    if (res.result == 'OK') {
                                      this.spinner.hide();
                                      this.router.navigate(['/wf-point-edit-comp']);
                                    } else {
                                      this.spinner.hide()
                                      this.router.navigate(['/wf-contract-err', { code: res.errorCode }]);
                                    }
                                  },
                                  (err) => {
                                    console_log(err);
                                    this.spinner.hide();
                                    this.router.navigate(['/wf-point-edit-comp']);
                                  }
                                );
      
                              }, 10000);
      
                            }
                          );

                        }, 10000);

                      }
                    );
                  } else {
                    this.spinner.hide()
                    this.router.navigate(['/wf-contract-err', { code: res.errorCode }]);
                  }
                },
                (err) => {
                  console_log(err);
                    this.data.ClubCode = '';
                    this.data.ContractTypeCode = '';
                    this.data.memberID = '';
                    this.data.memberName = '';
                    this.data.Point = '';
                    this.translate.get('BEA_MANAGE_POINT_ERROR.' + err.error.errorCode).subscribe(
                      (res) => {
                        document.getElementById('error').innerHTML = res;
                        document.getElementById('error').style.display = 'block';
                      }
                    );

                }
              );
            },
            (err) => {
              console_log(err);
              this.spinner.hide();
            }
          );
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
        }
      );
    }
  }
  async hashFunction(string) {
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  }
  back(){
    this.router.navigate(['/wf-point-edit']);
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
}