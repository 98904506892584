import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup} from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { console_log, DataProvider } from 'src/providers/data/data';
import { TranslateService } from '@ngx-translate/core';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mail-edit',
  templateUrl: './mail-edit.component.html',
  styleUrls: ['./mail-edit.component.scss'],
})
export class MyAccountMailEditComponent implements OnInit {
  public Last_Name = '';
  public First_Name = '';
  public User_Auth = '';
  public Department_Name = '';
  public User_Auth_Name = '';
  public Shop_Name = '';
  public Management_Company_Code = '';
  public New_Mail;
  public today;
  public app_date;
  constructor(    
    public data: DataProvider,
    private router: Router,
    public translate: TranslateService,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'myaccount';
    this.spinner.show();
    this.rest.Get_WFAccount(this.data.uuid).subscribe(
      (res) => {
        console_log(res);
        this.Last_Name = res.data.user_last_name;
        this.First_Name = res.data.user_first_name;
        this.Department_Name = res.data.department_name;
        this.Shop_Name = res.data.shop_name;
        this.User_Auth_Name = res.data.user_auth_type;
        this.Management_Company_Code = res.data.management_company_code;

        if (this.User_Auth_Name == 'システム管理者') {
          this.User_Auth = 'wf-sys-admin';
        } else if (this.User_Auth_Name == 'WF本部') {
          this.User_Auth = 'wf-corp-staff';
        } else if (this.User_Auth_Name == '直営店スタッフ') {
          this.User_Auth = 'wf-direct-staff';
        } else if (this.User_Auth_Name == '加盟店本部') {
          this.User_Auth = 'wf-fc-head';
        } else if (this.User_Auth_Name == '加盟店スタッフ') {
          this.User_Auth = 'wf-fc-staff';
        }
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
  dataValidation() {
    let err = false;
    let errTxt = '';
    
    if (!this.validateEmail(this.New_Mail)) {
      err = true;
      this.translate.get('MY_ACCOUNT.ERROR_MAIL').subscribe(
        (res) => {errTxt = res}
      );
    }
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      return false;
    }
    return true;
  }
  
  send() {
    this.spinner.show()
    if (this.dataValidation() == false) {
      this.spinner.hide();
      return true;
    } else {
      document.getElementById('error').style.display = 'none';
      const body = {
        data: {
          user_uuid: this.data.uuid,
          uuid: this.data.uuid,
          user_last_name: this.Last_Name,
          user_first_name: this.First_Name,
          user_auth: this.User_Auth,
          mail_address: this.New_Mail,
          department_name: this.Department_Name,
          shop_name: this.Shop_Name,
          management_company_code: this.Management_Company_Code
        }
      };
      console_log(body);
      this.rest.Update_CognitoUser(
        this.data.uuid,
        this.data.uuid,
        this.Last_Name,
        this.First_Name,
        this.User_Auth,
        this.New_Mail,
        this.Department_Name,
        this.Shop_Name,
        this.Management_Company_Code).subscribe(
          (res) => {
            console_log(res);
            this.spinner.hide();
            this.router.navigate(['/wf-myaccount-mail-sended']);
          },
          (err) => {
            console_log(err);
            this.spinner.hide();
            this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
          }
        );
    }
  }
  
  validateEmail(mail) {
    var reg = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    return reg.test(mail);
  }
}
