import { Component, OnInit } from '@angular/core';
import { RESOURCE_CACHE_PROVIDER } from '@angular/platform-browser-dynamic';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MANUAL_PDF_FC, MANUAL_PDF_HC } from 'src/environments/environment';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public pages;
  public Scope = '2';
  public Notice_Data = [];
  public noData = [];
  public management_company_code;
  constructor(
    public data: DataProvider,
    public rest: CallapiService,
    public router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-home';
    this.spinner.show();
    this.rest.Get_Notice(this.data.uuid, this.Scope).subscribe(
      (res)=>{
        this.Notice_Data = res.data;
        this.rest.Get_WFAccount(this.data.uuid).subscribe(
          (res)=>{
            console_log(res);
            this.management_company_code = res.data.management_company_code;
            this.spinner.hide();
          },
          (err)=>{
            console_log(res);
            this.spinner.hide();
          }
        );
      },(err)=>{
        this.spinner.hide();
      }
    );
  }
  ManualPDF(){
    if(this.management_company_code == 'HC01'){
      window.open(MANUAL_PDF_HC, '_blank', '');
    }else{
      window.open(MANUAL_PDF_FC, '_blank', '');
    }
  }
}