import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/utils/services/app.service';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ContractListComponent implements OnInit {
  public csv1;
  public CompanyName = '';
  public Telephone = '';
  public cancel_flg = '';
  public check: boolean = false;
  public documentpdf;
  public ContractCount = '';
  public pages1;
  public wf_contract_list_detail: boolean;
  public today;
  public today_date;
  public management_company_code;
  filteredItems = [];
  pages : number = 6;
  pageSize : number = 10;
  pageNumber : number = 0;
  currentIndex : number = 1;
  items = [];
  pagesIndex : Array<number>;
  pageStart : number = 1;

  constructor(
    private router: Router,
    public data: DataProvider,
    private appService: AppService,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-contract-list';
    this.spinner.show();
    if(this.check == true){
      this.cancel_flg = '1';
    }else{
      this.cancel_flg = ''
    }
    this.rest.Get_WFAccount(this.data.uuid).subscribe(
      (res)=>{
        this.management_company_code = res.data.management_company_code;
        const body = {
          data:{
            user_uuid: this.data.uuid,
            company_name: this.CompanyName,
            tel: this.Telephone,
            cancel_flg: this.cancel_flg,
            management_company_code: this.management_company_code
          }
        };
        console_log(body);
        this.rest.Get_Contracts(this.data.uuid, this.CompanyName, this.Telephone, this.cancel_flg, this.management_company_code).subscribe(
          (res)=>{
              console_log(res);
              this.filteredItems = res.data.CorporationInfo;
              this.ContractCount = res.contract_record_count;
              if (res.record_count >= 500) {
                document.getElementById('limit500').style.display = 'block';
              } else {
                document.getElementById('limit500').style.display = 'none';
              }
              this.currentIndex = 1;
              this.pageStart = 1;
              this.pages = 6;

              this.pageNumber = parseInt(
                '' + this.filteredItems.length / this.pageSize
              );
              if (this.filteredItems.length % this.pageSize != 0) {
                this.pageNumber++;
              }
              if (this.pageNumber < this.pages) {
                this.pages = this.pageNumber;
              }
              this.refreshItems();
              this.spinner.hide();
          },
          (err)=>{
            console_log(err);
            this.spinner.hide();
            this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
          }
        );
      },
      (err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        this.pages1 = res.data;
        for (let i = 0; i < this.pages1.length; i++) {
          if (this.pages1[i].pagename == 'wf-contract-list-detail') {
            console_log('wf-contract-list-detail');
            if (this.pages1[i].auth == 'F' || this.pages1[i].auth == 'R') {
              this.wf_contract_list_detail = true;
            }
            if (this.pages1[i].auth == 'D') {
              this.wf_contract_list_detail = false;
            }
          }
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }
  details(list) {
    this.data.backButton = '/wf-contract-list';
    this.router.navigate(['/wf-contract-list-detail',{uuid: list.uuid}]);
  }

  csv(){
    const bodyHC01 = {
      corporation_no : "法人番号",
      company_name : "会社名",
      tel : "電話番号",
      mail_address : "メールアドレス",
      judge_state: "契約状況",
      acquired_name: "獲得企業名",
      user_count: "社員数",
      app_date : "申請日",
      contract_start_date : "契約日",
      contract_end_date : "解約日"
    }
    const body = {
      corporation_no : "法人番号",
      company_name : "会社名",
      tel : "電話番号",
      mail_address : "メールアドレス",
      judge_state: "契約状況",
      user_count: "社員数",
      app_date : "申請日",
      contract_start_date : "契約日",
      contract_end_date : "解約日"
    }
    var csvItems = this.filteredItems
    for(let i = 0; i < csvItems.length; i++){
      if(csvItems[i].judge_state == '90' && csvItems[i].contract_end_date != ''){
        csvItems[i].judge_state = '解約済'
      }else if(csvItems[i].judge_state == '90' && csvItems[i].contract_end_date == ''){
        csvItems[i].judge_state = '契約中'
      }else if(csvItems[i].judge_state == '02'){
        csvItems[i].judge_state = '審査中'
      }else if(csvItems[i].judge_state == '03'){
        csvItems[i].judge_state = '再審査可能NG'
      }else if(csvItems[i].judge_state == '05'){
        csvItems[i].judge_state = '再審査中'
      }else if(csvItems[i].judge_state == '01' || csvItems[i].judge_state == '04' || csvItems[i].judge_state == '99'){
        csvItems[i].judge_state = ''
      }
    }
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    if(this.management_company_code == 'HC01'){
      const header = Object.keys(bodyHC01);
      const headerValue = Object.values(bodyHC01);
      this.csv1= csvItems.map(row => header.map(fieldName =>
      JSON.stringify(row[fieldName], replacer)).join(','));
      this.csv1.unshift(headerValue.join(','));
    }else{
      const header = Object.keys(body);
      const headerValue = Object.values(body);
      this.csv1= csvItems.map(row => header.map(fieldName =>
      JSON.stringify(row[fieldName], replacer)).join(','));
      this.csv1.unshift(headerValue.join(','));
    }
    this.csv1 = this.csv1.join('\r\n');

    // Create link and download

    this.today = new Date();
    this.today_date = this.datepipe.transform(this.today, 'yyyyMMddHHmmss');
    var a = '契約済み法人一覧_';
    var download_name;
    download_name = a + this.today_date;
    var link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(this.csv1));
    link.setAttribute('download', download_name + '.csv');
    link.style.visibility = 'hidden';
    for(let i = 0; i < csvItems.length; i++){
      if(csvItems[i].judge_state == '解約済' && csvItems[i].contract_end_date != ''){
        csvItems[i].judge_state = '90'
      }else if(csvItems[i].judge_state == '契約中' && csvItems[i].contract_end_date == ''){
        csvItems[i].judge_state = '90'
      }else if(csvItems[i].judge_state == '審査中'){
        csvItems[i].judge_state = '02'
      }else if(csvItems[i].judge_state == '再審査可能NG'){
        csvItems[i].judge_state = '03'
      }else if(csvItems[i].judge_state == '再審査中'){
        csvItems[i].judge_state = '05'
      }else if(csvItems[i].judge_state == ''){
        csvItems[i].judge_state = ''
      }
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  Search(){
    this.spinner.show();
    if(this.check == true){
      this.cancel_flg = '1';
    }else{
      this.cancel_flg = ''
    }

    const body = {
      data:{
        user_uuid: this.data.uuid,
        company_name: this.CompanyName,
        tel: this.Telephone,
        cancel_flg: this.cancel_flg,
        management_company_code: this.management_company_code
      }
    };
    console_log(body)
    this.rest.Get_Contracts(this.data.uuid, this.CompanyName, this.Telephone, this.cancel_flg,  this.management_company_code).subscribe(
      (res)=>{
        console_log(res);
        this.filteredItems = res.data.CorporationInfo;
        this.ContractCount = res.contract_record_count;
        // if (this.filteredItems.length >= 500) {
        //   document.getElementById('limit500').style.display = 'block';
        // } else {
        //   document.getElementById('limit500').style.display = 'none';
        // }
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }

        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
    },(err)=>{
      console_log(err);
      this.spinner.hide();
      this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
  pdf_download(uuid) {
    this.rest
      .Download_Regcertificates(this.data.uuid, uuid)
      .subscribe((res) => {
        if (res.code == 'S000001') {
          this.documentpdf = res.data.s3url;
          window.open(this.documentpdf, '_blank', '');
        }
      });
  }
  pdf_download_s3(uuid) {
    this.spinner.show();
    this.rest.Download_Contracts(this.data.uuid, uuid).subscribe(
        (res) => {
          if (res.code == 'S000001') {
            this.spinner.hide();
            window.open(res.data.s3url, '_blank', '');
          }
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
        }
      );
  }
  order(id){
    var apply_list:Array<Apply> = this.filteredItems;
    if(id == 1){
      apply_list.sort((a,b) =>  a.app_date < b.app_date ? 1 : a.app_date > b.app_date ? -1 : 0)	// descending
      this.items = apply_list.slice(
        (this.currentIndex - 1) * this.pageSize,
        this.currentIndex * this.pageSize
      );
      this.pagesIndex = this.fillArray();
    }else if(id == 2){
      apply_list.sort((a,b) => a.app_date < b.app_date ? -1 : a.app_date > b.app_date ? 1 : 0)	// Ascending
      this.items = apply_list.slice(
        (this.currentIndex - 1) * this.pageSize,
        this.currentIndex * this.pageSize
      );
      this.pagesIndex = this.fillArray();
    }else if(id == 3){
      apply_list.sort((a,b) => a.contract_start_date < b.contract_start_date ? 1 : a.contract_start_date > b.contract_start_date ? -1 : 0)	// descending
      this.items = apply_list.slice(
        (this.currentIndex - 1) * this.pageSize,
        this.currentIndex * this.pageSize
      );
      this.pagesIndex = this.fillArray();
    }else if(id == 4){
      apply_list.sort((a,b) => a.contract_start_date < b.contract_start_date ? -1 : a.contract_start_date > b.contract_start_date ? 1 : 0)	// Ascending
      this.items = apply_list.slice(
        (this.currentIndex - 1) * this.pageSize,
        this.currentIndex * this.pageSize
      );
      this.pagesIndex = this.fillArray();
    }else if(id == 5){
      apply_list.sort((a,b) => a.contract_end_date < b.contract_end_date ? 1 : a.contract_end_date > b.contract_end_date ? -1 : 0)	// descending
      this.items = apply_list.slice(
        (this.currentIndex - 1) * this.pageSize,
        this.currentIndex * this.pageSize
      );
      this.pagesIndex = this.fillArray();
    }else{
      apply_list.sort((a,b) => a.contract_end_date < b.contract_end_date ? -1 : a.contract_end_date > b.contract_end_date ? 1 : 0)	// Ascending
      this.items = apply_list.slice(
        (this.currentIndex - 1) * this.pageSize,
        this.currentIndex * this.pageSize
      );
      this.pagesIndex = this.fillArray();
    }
  }
}
class Apply{
  app_date: String
  company_name: String
  contract_end_date: String
  contract_start_date: String
  corporation_no: String
  mail_address: String
  tel: String
  uuid: String
}
