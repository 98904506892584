import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-unpaid-user-apply-conf',
  templateUrl: './unpaid-user-apply-conf.component.html',
  styleUrls: ['./unpaid-user-apply-conf.component.scss'],
})
export class UnpaidUserApplyComponent implements OnInit {
  public CasioID;
  public CompanyName;
  public UserNo;
  public UserName;
  public UnpaidAmount;
  public EndDate;
  public Corporation;
  constructor(
    private router: Router,
    public data: DataProvider,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public rest: CallapiService,) { }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-unpaid-list';
    this.widthChanger('main-header', 942);
    this.spinner.show();
    this.CasioID = this.route.snapshot.paramMap.get('casio_id');
    this.Corporation = this.route.snapshot.paramMap.get('corporation_no');
    this.CompanyName = this.route.snapshot.paramMap.get('company_name');
    this.UserNo = this.route.snapshot.paramMap.get('user_no');
    this.UserName = this.route.snapshot.paramMap.get('user_name');
    this.UnpaidAmount = this.route.snapshot.paramMap.get('unpaid_amount');
    this.EndDate = this.route.snapshot.paramMap.get('end_date');
    this.spinner.hide();
  }
  back() {
    this.router.navigate(['/wf-unpaid-user-list', {corporation_no: this.Corporation}]);
  }

  next() {
    this.spinner.show();
    const body = {
      data:{
        user_uuid: this.data.uuid,
        casio_id: this.CasioID
      }
    };
    console_log(body);
    this.rest.Update_UnpaidApply(this.data.uuid, this.CasioID).subscribe(
      (res) => {
        console_log(res);
        this.spinner.hide();
        this.router.navigate(['/wf-unpaid-user-apply-comp', {corporation_no: this.Corporation}]);
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    )
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i] as HTMLElement;
      header.style.minWidth = width.toString() + 'px';
    }
  }
}
