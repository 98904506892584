import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup} from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-unpaid-user-apply-comp',
  templateUrl: './unpaid-user-apply-comp.component.html',
  styleUrls: ['./unpaid-user-apply-comp.component.scss'],
})
export class UnpaidUserApplyCompleteComponent implements OnInit {
  public Corporation;
  constructor(
    private renderer: Renderer2,
    private router: Router,
    public data: DataProvider,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-unpaid-list';
    this.Corporation = this.route.snapshot.paramMap.get('corporation_no');
  }
  back() {
    this.router.navigate(['/wf-unpaid-user-list', {corporation_no: this.Corporation}]);
  }
}
