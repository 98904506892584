import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-mail-sended',
  templateUrl: './mail-sended.component.html',
  styleUrls: ['./mail-sended.component.scss'],
})
export class MyAccountMailSendedComponent implements OnInit, OnDestroy {
  public registerForm: FormGroup;
  constructor(
    public data: DataProvider,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private router: Router
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'mysettings';
    this.registerForm = new FormGroup({
      email: new FormControl(null, Validators.required)
    });
  }

  confirm() {
    if (this.registerForm.valid) {
      
    } else {
      this.toastr.error('Please insert the email!', 'Error!');
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'email-confirm-page'
    );
  }
}
