<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'ANNOUNCEMENT.TITLE' | translate }}</h3>
  
  <!-- Table section -->
  <section class="content" id="edit"> 
  <div class="card-body">
      <p class="left-border header-text2">{{ 'MODIFY_ANNOUNCEMENT.TITLE' | translate }}</p>
      <p>{{ 'MODIFY_ANNOUNCEMENT.TITLE_TEXT_2' | translate }}</p>
      <div class="container-fluid" style="color: black; ">
        <div class="row" style="overflow-x: scroll;margin-top: 30px;">
              <table id="example2" class="table table-bordered table-hover">
                <thead>
                </thead>
                <tbody>
                <tr>
                  <th><div class="card-body ftext">{{ 'ANNOUNCEMENT_FORM.DATE_DEST' | translate }}</div></th>
                  <td>
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="col-12 display">
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 display dis1">
                              <input 
                                type="text"
                                class="form-control ftext"
                                placeholder="2021-05-08"
                                onfocus="(this.type='date')"
                                onblur="(this.type='text')"
                                name="Date"
                                [(ngModel)]="Date">
                            </div>
                          </div>
                        </div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <th><div class="card-body ftext">{{ 'ANNOUNCEMENT_FORM.CONTENTS_DEST' | translate }}</div></th>
                  <td>
                      <div class="row">
                        <div class="col-12 col-md-12 col-xl-12">
                          <div class="col-12 display">
                            <div class="col-12 col-md-12 col-xl-12 display dis1">
                              <input 
                                type="text" 
                                class="form-control ftext"
                                placeholder="{{ 'ANNOUNCEMENT_FORM.CONTENTS_PLACEHOLDER' | translate }}"
                                name="Contents"
                                [(ngModel)]="Contents">
                            </div>
                          </div>
                        </div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <th><div class="card-body ftext">{{ 'ANNOUNCEMENT_FORM.PUBLICTAION_DATE_TIME_DEST' | translate }}</div></th>
                  <td>
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 display">
                            <div class="col-12 col-sm-12 col-md-3 col-lg-5 col-xl-3 display dis1">
                              <span class="car ftext">{{ 'ANNOUNCEMENT_FORM.PUBLICATION_DATE_DEST' | translate }}</span>
                            </div>
                            <div class="col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7 display dis1">
                              <input 
                                type="text"
                                class="form-control ftext"
                                placeholder="2021-05-08"
                                onfocus="(this.type='date')"
                                onblur="(this.type='text')"
                                name="DatePublic"
                                [(ngModel)]="DatePublic">
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 display">
                              <div class="col-12 col-sm-12 col-md-3 col-lg-5 col-xl-3 display dis1">
                                <span class="car ftext">{{ 'ANNOUNCEMENT_FORM.PUBLICATION_TIME_DEST' | translate }}</span>
                              </div>
                              <div class="col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7 display dis1">
                                <input 
                                  type="text"
                                  class="form-control ftext"
                                  placeholder="00:00"
                                  onfocus="(this.type='time')"
                                  onblur="(this.type='text')"
                                  name="TimePublic"
                                  [(ngModel)]="TimePublic">
                              </div>
                          </div>
                        </div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <th><div class="card-body ftext">{{ 'ANNOUNCEMENT_FORM.VISIBLE_OR_INVISIBLE_DEST' | translate }}</div></th>
                  <td>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 dis1">
                        <div class="col-12 dis1">
                          <div class="col-12 col-sm-5 col-md-4 col-lg-4 col-xl-3 display">
                            <div class="form-group">
                              <div class="custom-control custom-radio" (click)="selectedRadios(visible)">
                                <input class="custom-control-input custom-control-input-danger custom-control-input-outline" type="radio" id="customRadio4" name="customRadio2" checked>
                                <label for="customRadio4" class="custom-control-label ftext">{{ 'ANNOUNCEMENT_FORM.VISIBLE_RADIO_BUTTON' | translate }}</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-5 display">
                            <div class="form-group">
                              <div class="custom-control custom-radio" (click)="selectedRadios(invisible)">
                                <input class="custom-control-input custom-control-input-danger custom-control-input-outline" type="radio" id="customRadio5" name="customRadio2">
                                <label for="customRadio5" class="custom-control-label ftext">{{ 'ANNOUNCEMENT_FORM.INVISIBLE_RADIO_BUTTON' | translate }}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
              </tbody>
            </table>
              </div>
          </div>
        <!-- Button -->
        <div class="text-center">
          <div class="two-btn">
            <div class="display">
              <a (click)="back(1)" class="btn-grey">{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
              </a>
            </div>
            <div class="display">
              <a (click)="register()" class="btn-pink">{{ 'ANNOUNCEMENT_FORM.MODIFY_BUTTON' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Table section -->
    <section class="content" id="confirm" style="display: none;">
    <div class="card-body">
      <p class="left-border header-text2">{{ 'MODIFY_ANNOUNCEMENT.TITLE' | translate }}</p>
      <p>{{ 'MODIFY_ANNOUNCEMENT.TITLE_TEXT_3' | translate }}</p>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="invoice p-3 mb-3">
            <div class="row invoice-info" *ngIf="Date != null and Contents != null">
              <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2 invoice-col">
                <address>
                  {{Date}}
                </address>
              </div>
              <div class="col-12 col-md-7 col-sm-7 col-lg-5 col-xl-5 invoice-col">
                <address>
                  {{Contents}}
                </address>
              </div>
            </div>
            <div class="row invoice-info">
              <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2 invoice-col">
                <address>
                  2021/03/10
                </address>
              </div>
              <div class="col-12 col-md-7 col-sm-7 col-lg-5 col-xl-5 invoice-col">
                <address>
                  ◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯。
                </address>
              </div>
            </div>
            <div class="row invoice-info">
              <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2 invoice-col">
                <address>
                  2021/03/10
                </address>
              </div>
              <div class="col-12 col-md-7 col-sm-7 col-lg-5 col-xl-5 invoice-col">
                <address>
                  ◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯。
                </address>
              </div>
            </div>
            <div class="row invoice-info">
              <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2 invoice-col">
                <address>
                  2021/03/10
                </address>
              </div>
              <div class="col-12 col-md-7 col-sm-7 col-lg-5 col-xl-5 invoice-col">
                <address>
                  ◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯◯。
                </address>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Table section end -->
    <div class="text-center finish2" style="font-size: 20px;">
      <p>{{ 'MODIFY_ANNOUNCEMENT.CENTER_TEXT' | translate }}</p>
    </div>
    <!-- button -->
    <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="back(2)" class="btn-grey">{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="finish()" class="btn-pink">{{ 'COMMON.COMMON_OKEY_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- button end -->
    </section>
</div>