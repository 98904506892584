import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class AccountCreateComponent implements OnInit {
  public Last_Name = '';
  public First_Name = '';
  public User_Auth = '';
  public Mail = '';
  public Management_Company_Code = '';
  public DepartmentName = '';
  public StoreName = '';

  public User_Auth_Name = '';
  public AcquiredData = '';
  public AcquiredInfo = [];
  public ShopData = [];
  public UserAuthData = [];
  constructor(
    private router: Router, 
    public data: DataProvider,
    public rest: CallapiService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-account-create';
    this.widthChanger('main-header', 942);
    this.spinner.show();
    this.rest.Get_Authtypes(this.data.uuid, 'W').subscribe(
      (res) => {
        console_log(res);
        this.UserAuthData = res.data;
        this.rest.Get_AcquiredMaster(this.data.uuid).subscribe(
          (res) => {
            this.spinner.hide();
            this.AcquiredInfo = res.data.AcquiredInfo;
            var acquired_data = this.AcquiredData.split(":");
            this.Management_Company_Code = acquired_data[0];
            this.rest.Get_ShopNames(this.data.uuid, this.Management_Company_Code).subscribe(
              (res) => {
                this.spinner.hide();
                console_log(res);
                this.ShopData = res.data.shopnames;
              },
              (err) => {
                this.spinner.hide();
                this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
              }
            );
          },
          (err) => {
            this.spinner.hide();
            this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
          }
        );
      },
      (err) => {
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
  // shopNames Function
  shopNames(event){
    var acquired_data = event.split(":");
    this.Management_Company_Code = acquired_data[0];
     this.rest.Get_ShopNames(this.data.uuid, this.Management_Company_Code).subscribe(
      (res) => {
        this.spinner.hide();
        console_log(res);
        this.ShopData = res.data.shopnames;
        if(res.data.shopnames == ''){
          this.StoreName = '';
        }
      },
      (err) => {
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
  userAuth(User_Auth){
    if(User_Auth == 'wf-sys-admin'){
      this.User_Auth_Name = 'システム管理者';
      this.StoreName = '';
    }else if(User_Auth == 'wf-corp-staff'){
      this.User_Auth_Name = 'WF本部';
      this.StoreName = '';
    }else if(User_Auth == 'wf-direct-staff'){
      this.User_Auth_Name = '直営店スタッフ';
      this.DepartmentName = '';
    }else if(User_Auth == 'wf-fc-head'){
      this.User_Auth_Name = '加盟店本部';
      this.StoreName = '';
    }else if(User_Auth == 'wf-fc-staff'){
      this.User_Auth_Name = '加盟店スタッフ';
      this.DepartmentName = '';
    }
  }
  dataValidation() {
    let err = false;
    let errTxt = '';
    
    if (!this.validateEmail(this.Mail)) {
      err = true;
      this.translate
        .get('ACCOUNT_CREATE_VALIDATION.ERROR_MAIL')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Mail == null || this.Mail == '') {
      err = true;
      this.translate
        .get('ACCOUNT_CREATE_VALIDATION.ERROR_MAIL')
        .subscribe((res) => (errTxt = res));
    }
    if(this.User_Auth == 'wf-direct-staff' || this.User_Auth == 'wf-fc-staff'){
      if(this.StoreName == '' || this.StoreName == null){
        err = true;
        this.translate
          .get('ACCOUNT_CREATE_VALIDATION.ERROR_SHOP_NAME')
          .subscribe((res) => (errTxt = res));
      }
    }
    if(this.User_Auth == 'wf-fc-head' || this.User_Auth == 'wf-fc-staff'){
      if (this.AcquiredData == '' || this.AcquiredData == null) {
        err = true;
        this.translate
          .get('ACCOUNT_CREATE_VALIDATION.ERROR_MANAGEMENT_COMPANY')
          .subscribe((res) => (errTxt = res));
      }
    }else{
      this.AcquiredData = '';
    }
    if(this.User_Auth == 'wf-sys-admin' || this.User_Auth == 'wf-corp-staff' || this.User_Auth == '' || this.User_Auth == 'wf-fc-head'){
      if (this.DepartmentName == null || this.DepartmentName == '') {
        err = true;
        this.translate
          .get('ACCOUNT_CREATE_VALIDATION.ERROR_DEPARTMENT_NAME')
          .subscribe((res) => (errTxt = res));
        }
      }else{
      this.DepartmentName = '';
    }
    if (this.User_Auth == '' || this.User_Auth == null) {
      err = true;
      this.translate
        .get('ACCOUNT_CREATE_VALIDATION.ERROR_AUTHORITY')
        .subscribe((res) => (errTxt = res));
    }
    if (this.First_Name == null || this.First_Name == '') {
      err = true;
      this.translate
        .get('ACCOUNT_CREATE_VALIDATION.ERROR_FIRST_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Last_Name == null || this.Last_Name == '') {
      err = true;
      this.translate
        .get('ACCOUNT_CREATE_VALIDATION.ERROR_LAST_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      return false;
    }
    return true;
  }
  back() {
    document.getElementById('error').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
    document.getElementById('confirm').style.display = 'none';
  }

  Next() {
    if(this.User_Auth == 'wf-corp-staff' || this.User_Auth == 'wf-sys-admin' || this.User_Auth == 'wf-direct-staff'){
      this.Management_Company_Code = 'HC01';
    }else{
      var acquired_data = this.AcquiredData.split(":");
      this.Management_Company_Code = acquired_data[0];
    }
    if (this.dataValidation() == false) {
      return true;
    } else {
      const body = {
        data:{ 
          user_uuid: this.data.uuid,
          user_last_name: this.Last_Name,
          user_first_name: this.First_Name,
          user_auth: this.User_Auth,
          mail_address: this.Mail,
          management_company_code: this.Management_Company_Code,
          department_name: this.DepartmentName,
          shop_name: this.StoreName
        }
      };
      console_log(body);
      document.getElementById('confirm').style.display = 'block';
      document.getElementById('edit').style.display = 'none';
    }
  }

  finish() {
    this.spinner.show();
    if(this.User_Auth == 'wf-corp-staff' || this.User_Auth == 'wf-sys-admin' || this.User_Auth == 'wf-direct-staff'){
      this.Management_Company_Code = 'HC01';
    }else{
      var acquired_data = this.AcquiredData.split(":");
      this.Management_Company_Code = acquired_data[0];
    }
    this.rest.Create_CognitoUser(
      this.data.uuid,
      this.Last_Name,
      this.First_Name,
      this.User_Auth,
      this.Mail,
      this.Management_Company_Code,
      this.DepartmentName,
      this.StoreName).subscribe(
        (res) => {
          this.spinner.hide();
          console_log(res);
          this.router.navigate(['/wf-account-create-comp']);
        },
        (err) => {
          this.spinner.hide();
          this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
        }
    );
  }
  
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
  validateEmail(mail) {
    var reg = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    return reg.test(mail);
  }
}
